<template>
  <div>
    <cs-button
      fill="outline"
      @click="showModal = true"
      corners="rounded"
      size="small"
      >Edit</cs-button
    >
    <cs-modal
      :show="showModal"
      class="bp-modal"
      @close="showModal = false"
      layout="large"
    >
      <div slot="header">
        Edit CDN Settings: General for {{ distribution.id }}
      </div>
      <div slot="body">
        <cs-form class="bp-modal__vertical-form">
          <cs-input
            label="Source Path"
            v-model="options.path"
            default-label="Leave blank to use the root folder"
          >
          </cs-input>
          <cs-input
            class="mt-2"
            label="Monthly Bandwidth Limit"
            v-model="options.monthly_bandwidth_limit"
            default-label="Set to 0 for unlimited"
          >
          </cs-input>
          <div class="toggles">
            <cs-toggle
              v-model="options.enabled"
              label-checked="Status"
              label-unchecked="Status"
            />
            <cs-toggle
              label-checked="DDOS Shield"
              label-unchecked="DDOS Shield"
              v-model="options.shield_ddos_protection_enabled"
            />
          </div>
        </cs-form>
        <cs-alert
          v-if="errorTitle"
          :title="errorTitle"
          :content="errorDescription"
          variant="danger"
        />
      </div>
      <cs-button
        slot="main"
        variant="primary"
        @click="save"
        fill="solid"
        :disabled="saving"
        corners="rounded"
        >Apply</cs-button
      >
      <div v-if="saving" class="bp-modal__loading" slot="body">
        <div class="bp-modal__loading-background"></div>
        <cs-spinner />
      </div>
    </cs-modal>
  </div>
</template>
<script>
import UpdateDistributionBasic from '@/gql/distributions/UpdateDistributionBasic.gql';

export default {
  components: {},
  props: {
    distribution: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      saving: false,
      showModal: false,
      errorTitle: null,
      errorDescription: null,
      options: {
        id: this.distribution.bunny_id,
        path: this.distribution.path,
        enabled: this.distribution.enabled,
        monthly_bandwidth_limit: this.distribution.monthly_bandwidth_limit,
        shield_ddos_protection_enabled:
          this.distribution.shield_ddos_protection_enabled,
      },
    };
  },
  methods: {
    async save() {
      try {
        this.saving = true;
        this.options.monthly_bandwidth_limit *= 1;
        await this.$apollo.mutate({
          mutation: UpdateDistributionBasic,
          variables: this.options,
        });
        this.saving = false;
        this.showModal = false;
        this.$emit('success');
        this.errorTitle = null;
        this.errorDescription = null;
      } catch (err) {
        this.saving = false;
        this.errorTitle = err.message;
        this.errorDescription =
          err.graphQLErrors[0].extensions.exception.description;
      }
      this.saving = false;
    },
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.bp-modal.cs-modal {
  /* ---cs-modal-width: 550px !important; */
  ---cs-modal-max-height: 90%;
}
.bp-modal.cs-modal >>> .cs-modal__popup {
  border: none;
}
.bp-modal__vertical-form {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
}
.bp-modal__vertical-form >>> .cs-textstyle-label-text {
  font-weight: bold;
}
.bp-modal__vertical-form >>> .cs-select__label {
  display: block;
}

.bp-modal__loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bp-modal__loading,
.bp-modal__loading-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.bp-modal__loading-background {
  background-color: var(--cs-gray-06);
  opacity: 0.2;
}

.toggles {
  display: flex;
  flex-direction: row;
  gap: 90px !important;
  margin-top: 40px;
}
.cs-toggle {
  --cs-toggle-color: var(--cs-success-base) !important;
}
* >>> .cs-toggle__label {
  flex-direction: row-reverse;
}
* >>> .cs-toggle__label-text {
  margin-left: 0px !important;
  margin-right: 5px;
  font-weight: 600;
  font-size: 16px;
}
</style>
