<template>
  <app-gray-card>
    <app-list-control-bar
      @refresh="refresh()"
      placeholder="Filter accelerated upload jobs"
      v-model="searchQuery"
    >
      <app-upload-button
        :bucketId="bucketId"
        :prefix="prefix"
        @upload="refresh()"
      />
    </app-list-control-bar>
    <b-table
      :items="jobs"
      :fields="fields"
      :filter="searchQuery"
      outlined
      table-variant="light"
      :busy="$apollo.queries.jobs.loading"
      show-empty
      thead-tr-class="eca-dashboard__table-head-row"
      tbody-tr-class="eca-dashboard__table-row"
    >
      <div slot="table-busy" class="bp-table-empty">
        <cs-spinner />
      </div>
      <div slot="empty" class="bp-table-empty">
        You haven't uploaded anything here yet.
      </div>
      <div slot="emptyfiltered" class="bp-table-empty">
        No matching uploads.
      </div>
      <template v-slot:cell(created_at)="data">
        <app-display-date-time :value="data.item.created_at" />
      </template>
      <template v-slot:cell(status)="data">
        <cs-tag :class="data.item.status">{{ data.item.status }}</cs-tag>
      </template>
    </b-table>
  </app-gray-card>
</template>
<script>
import AppUploadButton from '@/components/upload/UploadButton.vue';
import ListUploadJobs from '@/gql/upload/ListUploadJobs.gql';
import AppListControlBar from '@/components/general/ListControlBar.vue';
import AppGrayCard from '@/components/general/GrayCard.vue';
import AppDisplayDateTime from '@/components/general/DisplayDateTime.vue';

export default {
  components: {
    AppUploadButton,
    AppListControlBar,
    AppGrayCard,
    AppDisplayDateTime,
  },
  props: {
    bucketId: {
      type: String,
      required: true,
    },
    prefix: {
      type: String,
      required: false,
    },
  },
  apollo: {
    jobs: {
      query: ListUploadJobs,
      variables() {
        return {
          // bucket_id: this.bucketId,
          prefix: this.prefix,
        };
      },
    },
  },
  methods: {
    refresh() {
      return this.$apollo.queries.jobs.refetch();
    },
  },
  data() {
    return {
      searchQuery: '',
      fields: [
        {
          key: 'path_key',
          label: 'Path',
        },
        {
          key: 'content_type',
        },
        {
          key: 'created_at',
          label: 'Uploaded',
        },
        {
          key: 'region',
          label: 'Uploaded Region',
        },
        {
          key: 'size',
          formatter: (value) => this.$options.filters.size(value),
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'status',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
      ],
    };
  },
};
</script>
