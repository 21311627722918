<template>
  <div>
    <cs-button @click="showModal = true" corners="rounded"
      >Add Trigger</cs-button
    >
    <cs-modal
      layout="large"
      :show="showModal"
      @close="showModal = false"
      class="add-trigger-modal"
    >
      <div slot="header">
        <h4>Add Trigger</h4>
      </div>
      <div slot="body" v-if="!saving" class="add-trigger-form">
        <div class="row trigger-box">
          <div class="col-md-3">
            <h5>WHEN</h5>
          </div>
          <div class="col-md-9">
            <cs-select
              label="Choose an event"
              :options="eventOptions"
              v-model="trigger.event_id"
            />
          </div>
        </div>
        <div class="row trigger-box">
          <div class="col-md-3">
            <h5>IF</h5>
          </div>
          <div class="col-md-9">
            <div class="cs-textstyle-label-text">Select conditions</div>
            <div v-for="filter of trigger.filters">
              <app-trigger-filter
                :key="filter.id"
                @remove="removeFilter(filter)"
                v-model="filter.value"
              />
            </div>
            <cs-button
              @click="addFilter()"
              size="small"
              fill="outline"
              corners="rounded"
              >{{
                trigger.filters.length
                  ? 'Add another condition'
                  : 'Add condition '
              }}</cs-button
            >
          </div>
        </div>
        <div class="row trigger-box">
          <div class="col-md-3">
            <h5>THEN</h5>
          </div>
          <div class="col-md-9">
            <cs-select
              :options="actionOptions"
              label="Choose an action"
              v-model="trigger.action_id"
            ></cs-select>
          </div>
        </div>
        <div class="row trigger-box" v-if="selectedAction">
          <div class="col-md-3">
            <h5>WITH OPTIONS</h5>
          </div>
          <div class="col-md-9">
            <cs-select
              required
              label="Output Bucket"
              v-model="trigger.output_bucket_id"
              :options="bucketOptions"
            />
            <cs-input
              required
              label="Output Path"
              v-model="trigger.output_path"
              default-label="This will be appended to your input file path"
            />
            <cs-input
              v-for="v of selectedAction.variables"
              :key="v.id"
              :required="v.required"
              :label="v.label"
              v-model="trigger.parameters[v.name]"
            />
          </div>
        </div>
      </div>
      <div slot="body" v-if="saving">
        <cs-spinner />
      </div>
      <cs-button slot="main" @click="addTrigger()" :disabled="saving"
        >Add</cs-button
      >
    </cs-modal>
  </div>
</template>
<script>
import ListActions from '@/gql/actions/ListActions.gql';
import ListBuckets from '@/gql/buckets/ListBuckets.gql';
import AddTrigger from '@/gql/triggers/AddTrigger.gql';
import AppTriggerFilter from '@/components/triggers/TriggerFilter.vue';

export default {
  components: {
    AppTriggerFilter,
  },
  props: {
    bucketId: {
      type: String,
      required: true,
    },
  },
  apollo: {
    actions: ListActions,
    buckets: ListBuckets,
  },
  data() {
    return {
      saving: false,
      showModal: false,
      eventOptions: [
        {
          label: 'An object is added to this bucket',
          value: 'add',
        },
      ],
      trigger: {
        bucket_id: this.bucketId,
        output_bucket_id: this.bucketId,
        event_id: 'add',
        action_id: null,
        filters: [],
        parameters: {},
      },
    };
  },
  computed: {
    actionOptions() {
      return (
        this.actions &&
        this.actions.map((action) => ({
          label: action.name,
          value: action.id,
        }))
      );
    },
    selectedAction() {
      return (
        this.trigger.action_id &&
        this.actions.find((a) => a.id == this.trigger.action_id)
      );
    },
    bucketOptions() {
      return (
        this.buckets &&
        this.buckets.map((b) => {
          return {
            value: b.id,
            label: `${b.provider.id}/${b.name}`,
          };
        })
      );
    },
  },
  watch: {
    selectedAction() {
      if (this.selectedAction) {
        this.trigger.output_path = this.selectedAction.default_destination_path;
      }
    },
  },
  methods: {
    addFilter() {
      this.trigger.filters.push({
        id: Math.random(),
        value: { criteria: '', value: '' },
      });
    },
    removeFilter(filter) {
      this.trigger.filters = this.trigger.filters.filter((a) => a != filter);
    },
    async addTrigger() {
      try {
        const variables = {
          eventId: this.trigger.event_id,
          actionId: this.trigger.action_id,
          bucketId: this.trigger.bucket_id,
          jobParameters: Object.keys(this.trigger.parameters).map((key) => ({
            key: key,
            value: this.trigger.parameters[key],
          })),
          conditions: this.trigger.filters.map((filter) => ({
            key: filter.value.criteria,
            value: filter.value.value,
          })),
          outputBucketId: this.trigger.output_bucket_id,
          outputPath: this.trigger.output_path,
        };
        this.saving = true;
        const resp = await this.$apollo.mutate({
          mutation: AddTrigger,
          variables,
        });
        this.$emit('added');
        this.showModal = false;
      } catch (err) {
        alert(err.message);
      }
      this.saving = false;
    },
  },
};
</script>
<style scoped>
.add-trigger-form {
  padding-top: 10px;
  min-height: 500px;
}
.add-trigger-form >>> label {
  display: block;
}

.trigger-label {
  width: 150px;
  padding-bottom: 50px;
}
.trigger-box {
  border-bottom: solid 1px var(--cs-gray-01);
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.add-trigger-modal >>> .cs-modal__popup {
  width: 900px !important;
}
</style>
