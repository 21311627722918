<template>
  <div>
    <div class="eca-dashboard__header mb-4">
      <div class="cs-textstyle-page-title">CDN</div>
      <cs-button corners="rounded" @click="showCreateDistributionModal = true"
        >Create Distribution</cs-button
      >
    </div>
    <app-gray-card>
      <app-list-control-bar
        v-model="searchQuery"
        placeholder="Filter distributions"
        @refresh="refresh()"
      >
      </app-list-control-bar>
      <b-table
        :items="distributions"
        :fields="fields"
        :filter="searchQuery"
        hover
        outlined
        selectable
        table-variant="light"
        show-empty
        :busy="$apollo.queries.distributions.loading"
        select-mode="single"
        thead-tr-class="eca-dashboard__table-head-row"
        tbody-tr-class="eca-dashboard__table-row"
        @row-selected="onDistributionSelected"
      >
        <div slot="table-busy" class="bp-table-empty">
          <cs-spinner />
        </div>
        <div slot="empty" class="bp-table-empty-column">
          <div class="cs-textstyle-item-heading">
            You haven't added any distributions yet.
          </div>
          <cs-button
            corners="rounded"
            @click="showCreateDistributionModal = true"
            >Create Distribution</cs-button
          >
        </div>
        <div slot="emptyfiltered" class="bp-table-empty">
          No matching distributions.
        </div>
        <template v-slot:cell(created_at)="data">
          <app-display-date-time :value="data.item.created_at" />
        </template>
        <template v-slot:cell(name)="data">
          {{ data.item.bucket.name }}
        </template>
        <template v-slot:cell(action)="data">
          <i :id="`${data.item.id}`" class="cs-icons-options"></i>
          <b-popover
            :ref="`${data.item.id}-menu`"
            :target="`${data.item.id}`"
            placement="bottom"
            triggers="blur hover"
            custom-class="bg-dark"
          >
            <div>
              <div class="bp-service-list__menu-body">
                <div
                  class="bp-service-list__menu-item"
                  @click.stop="purge(data.item)"
                >
                  Purge
                </div>
                <div
                  class="bp-service-list__menu-item"
                  @click.stop="remove(data.item)"
                >
                  Delete
                </div>
              </div>
            </div>
          </b-popover>
        </template>
      </b-table>
      <!-- <app-create-distribution-modal
        :provider_id="provider_id"
        :bucket_name="bucket_name"
        :show="showCreateDistributionModal"
        @success="refresh()"
        @close="showCreateDistributionModal = false"
      /> -->
    </app-gray-card>
  </div>
</template>
<script>
import ListAllDistributions from '@/gql/distributions/ListAllDistributions.gql';
import AppListControlBar from '@/components/general/ListControlBar.vue';
import AppGrayCard from '@/components/general/GrayCard.vue';
import AppDisplayDateTime from '@/components/general/DisplayDateTime.vue';
// import AppCreateDistributionModal from '@/components/distributions/CreateDistributionModal.vue';

import {
  purgeDistribution,
  deleteDistribution,
} from '@/services/confirm-delete';

export default {
  components: {
    AppListControlBar,
    AppGrayCard,
    AppDisplayDateTime,
    // AppCreateDistributionModal,
  },
  data() {
    return {
      showCreateDistributionModal: false,
      searchQuery: '',
      fields: [
        {
          key: 'id',
          label: 'CDN Name',
        },
        {
          key: 'name',
          label: 'Bucket Name',
          thClass: 'eca-dashboard__table-head',
        },
        {
          key: 'created_at',
          label: 'Created Date',
        },
        {
          key: 'action',
          label: '',
        },
      ],
    };
  },
  apollo: {
    distributions: ListAllDistributions,
  },
  methods: {
    refresh() {
      this.$apollo &&
        this.$apollo.queries &&
        this.$apollo.queries.distributions &&
        this.$apollo.queries.distributions.refetch();
    },
    onDistributionSelected(rows) {
      const distribution = rows[0];
      if (distribution) {
        this.$router.push({
          name: 'DistributionDetail',
          params: {
            id: distribution.id,
          },
          query: {
            tab: this.$route.query.tab,
          },
        });
      }
    },
    async purge(distribution) {
      const response = await purgeDistribution(distribution);
      if (response) this.$dialog.alert('Distribution purged');
    },
    async remove(distribution) {
      const response = await deleteDistribution(distribution);
      if (response) this.refresh();
    },
  },
  beforeMount() {
    this.refresh();
  },
};
</script>

<style scoped>
.bp-table-empty-column {
  text-align: center;
  padding: 20px;
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
