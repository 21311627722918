<template>
  <div>
    <cs-button
      fill="outline"
      @click="showModal = true"
      corners="rounded"
      size="small"
      >Edit</cs-button
    >
    <cs-modal
      :show="showModal"
      class="bp-modal"
      @close="showModal = false"
      layout="large"
    >
      <div slot="header">Edit Image Optimizer</div>
      <div slot="body">
        <cs-alert
          v-if="errorTitle"
          :title="errorTitle"
          :content="errorDescription"
          variant="danger"
        />
        <cs-form class="bp-modal__vertical-form">
          <cs-toggle
            label-checked="Enable Optimizer"
            label-unchecked="Enable Optimizer"
            v-model="options.optimizer_enabled"
          />
          <cs-input
            label="Desktop Max Image Width(px)"
            type="number"
            v-model="options.optimizer_desktop_max_width"
            v-if="options.optimizer_enabled"
          />
          <cs-input
            label="Desktop Image Quality(%)"
            type="number"
            v-model="options.optimizer_image_quality"
            v-if="options.optimizer_enabled"
          />
          <cs-input
            label="Mobile Max Image Width(px)"
            type="number"
            v-model="options.optimizer_mobile_max_width"
            v-if="options.optimizer_enabled"
          />
          <cs-input
            label="Mobile Image Quality(%)"
            type="number"
            v-model="options.optimizer_mobile_image_quality"
            v-if="options.optimizer_enabled"
          />
          <cs-toggle
            label-checked="Use WebP where supported"
            label-unchecked="Use WebP where supported"
            v-model="options.optimizer_enable_webp"
            v-if="options.optimizer_enabled"
          />
        </cs-form>
      </div>
      <cs-button
        slot="main"
        variant="primary"
        @click="save"
        fill="solid"
        corners="rounded"
        :disabled="saving"
        >Save</cs-button
      >
      <div v-if="saving" class="bp-modal__loading" slot="body">
        <div class="bp-modal__loading-background"></div>
        <cs-spinner />
      </div>
    </cs-modal>
  </div>
</template>

<script>
import UpdateDistributionOptimizer from '@/gql/distributions/UpdateDistributionOptimizer.gql';

export default {
  components: {},
  props: {
    distribution: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      saving: false,
      showModal: false,
      errorTitle: null,
      errorDescription: null,
      options: {
        id: this.distribution.bunny_id,
        optimizer_enabled: this.distribution.optimizer_enabled,
        optimizer_desktop_max_width:
          this.distribution.optimizer_desktop_max_width,
        optimizer_mobile_max_width:
          this.distribution.optimizer_mobile_max_width,
        optimizer_image_quality: this.distribution.optimizer_image_quality,
        optimizer_mobile_image_quality:
          this.distribution.optimizer_mobile_image_quality,
        optimizer_enable_webp: this.distribution.optimizer_enable_webp,
        optimizer_minify_css: this.distribution.optimizer_minify_css,
        optimizer_minify_javascript:
          this.distribution.optimizer_minify_javascript,
      },
    };
  },
  methods: {
    async save() {
      try {
        this.saving = true;
        this.options.optimizer_desktop_max_width *= 1;
        this.options.optimizer_mobile_max_width *= 1;
        this.options.optimizer_image_quality *= 1;
        this.options.optimizer_mobile_image_quality *= 1;
        this.options.optimizer_enabled =
          this.options.optimizer_enabled || false;
        this.options.optimizer_enable_webp =
          this.options.optimizer_enable_webp || false;
        this.options.optimizer_minify_css =
          this.options.optimizer_minify_css || false;
        this.options.optimizer_minify_javascript =
          this.options.optimizer_minify_javascript || false;
        const resp = await this.$apollo.mutate({
          mutation: UpdateDistributionOptimizer,
          variables: this.options,
        });
        this.saving = false;
        this.showModal = false;
        this.$emit('success');
        this.errorTitle = null;
        this.errorDescription = null;
      } catch (err) {
        this.errorTitle = err.message;
        this.errorDescription =
          err.graphQLErrors[0].extensions.exception.description;
      }
      this.saving = false;
    },
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.bp-modal.cs-modal {
  /* ---cs-modal-width: 550px !important; */
  ---cs-modal-max-height: 90%;
}
.bp-modal.cs-modal >>> .cs-modal__popup {
  border: none;
}
.bp-modal__vertical-form {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
}
.bp-modal__vertical-form >>> .cs-textstyle-label-text {
  font-weight: bold;
}
.bp-modal__vertical-form >>> .cs-select__label {
  display: block;
}

.bp-modal__loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bp-modal__loading,
.bp-modal__loading-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.bp-modal__loading-background {
  background-color: var(--cs-gray-06);
  opacity: 0.2;
}
.bp-modal__vertical-form .cs-toggle {
  --cs-toggle-color: var(--cs-success-base);
}
</style>
