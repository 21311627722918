<template>
  <div class="bp-simple-card-table">
    <b-table
      v-if="items"
      v-bind="$attrs"
      :fields="fields"
      :items="items"
      thead-class="d-none"
      show-empty
      :tbody-tr-class="customClasses"
    >
      <div slot="empty" class="bp-table-empty">No data</div>
      <template v-slot:cell(label)="data">
        <span class="cs-textstyle-paragraph-bold">{{ data.item.label }}</span>
        <div
          v-if="data.item.labelNote || data.item.labelNoteIcon"
          class="cs-textstyle-paragraph-small bp-simple-card-table__label-note"
        >
          <i
            v-if="data.item.labelNoteIcon"
            :class="data.item.labelNoteIcon"
            class="bp-simple-card-table__label-note-icon"
          />
          <span v-if="data.item.labelNote">{{ data.item.labelNote }}</span>
        </div>
      </template>
      <template v-slot:cell(value)="data">
        <span
          class="cs-textstyle-paragraph"
          :class="enabledDisabledClass(data.item.value)"
          >{{ data.item.value }}</span
        >
      </template>
      <slot />
    </b-table>
  </div>
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'label',
        },
        {
          key: 'value',
          tdClass: 'text-right',
        },
      ],
    };
  },
  computed: {
    enabledDisabledClass() {
      return (value) => {
        if (!value) return '';
        if (typeof value !== 'string') return '';
        if (value.toLowerCase() === 'enabled')
          return 'bp-simple-card-table__col--enabled-color';
        if (value.toLowerCase() === 'disabled')
          return 'bp-simple-card-table__col--disabled-color';
        return '';
      };
    },
    customClasses() {
      return (item) => {
        return [
          'bp-simple-card-table__tr',
          this.isDisabledClass(item),
          this.isSubClass(item),
        ];
      };
    },
    isDisabledClass() {
      return (item) =>
        item.disabled ? 'bp-simple-card-table__tr--disabled' : '';
    },
    isSubClass() {
      return (item) =>
        item.subItem ? 'bp-simple-card-table__tr--sub-level' : '';
    },
  },
};
</script>
<style scoped>
.bp-simple-card-table {
  margin-bottom: -16px;
}
/* Borders/padding/general color */
.bp-simple-card-table >>> .bp-simple-card-table__tr td {
  color: var(--cs-gray-06);
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 0;
  padding-right: 0;
  border-bottom-color: var(--cs-gray-01);
  vertical-align: middle;
}
.bp-simple-card-table >>> .bp-simple-card-table__tr td:first-child {
  padding-right: 8px;
}
.bp-simple-card-table >>> .bp-simple-card-table__tr td:last-child {
  padding-left: 8px;
}
.bp-simple-card-table >>> .bp-simple-card-table__tr:last-of-type td {
  border-bottom: none;
}

.bp-simple-card-table >>> .bp-simple-card-table__tr--disabled td,
.bp-simple-card-table >>> .bp-simple-card-table__tr--disabled td span {
  background-color: var(--cs-gray-01);
  color: var(--cs-gray-03);
}
.bp-simple-card-table >>> .bp-simple-card-table__tr--sub-level td {
  padding-right: 10px;
  padding-left: 10px;
}

/* Green/red color for enabled/disabled text */
.bp-simple-card-table__col--enabled-color {
  color: var(--cs-success-base);
}
.bp-simple-card-table__col--disabled-color {
  color: var(--cs-danger-base);
}

/* Label note */
.bp-simple-card-table__label-note {
  color: var(--cs-gray-04);
}
.bp-simple-card-table__label-note-icon {
  font-size: 16px;
  margin-right: 4px;
}
</style>
