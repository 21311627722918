import filetypes from '@/assets/filetypes.json';
import $apollo from '@/services/apollo';
import GetFileSignedUrl from '@/gql/files/GetFileSignedUrl.gql';

function getExtension(key) {
  if (!key) {
    return null;
  }
  const filepath = key.split('/').pop();
  if(filepath.indexOf('.') < 0) {
    return 'folder';
  }
  const parts = filepath.split('.');
  if (parts.length > 1) {
    return parts.pop();
  }
  return null;
}

function getIconForKey(key, size, iconFamily) {
  iconFamily = iconFamily || 'material-rounded';
  size = size || 50;
  const extension = getExtension(key);
  const iconId = filetypes[extension] || 'file';
  return `https://img.icons8.com/${iconFamily}/${size}/${iconId}.png`;
}

async function getFileSignedUrl(providerId, bucketName, pathKey) {
  const resp = await $apollo.query({
    query: GetFileSignedUrl,
    variables: {
      provider_id: providerId,
      bucket_name: bucketName,
      path_key: pathKey,
    },
  });
  return resp.data.url;
}

export { getIconForKey, getFileSignedUrl };
