<template>
  <div>
    <app-bucket-breadcrumbs />
    <h1>{{ path_key.substring(0, path_key.lastIndexOf('/')) }}</h1>
    <cs-page-tabs class="bp-tabs tabs-without-x-padding" v-if="!$apollo.queries.bucket.loading">
      <cs-page-tab label="Files">
        <app-files-tab
          :prefix="path_key"
          v-if="bucket"
          :bucketId="bucket.id"
        />
      </cs-page-tab>
      <cs-page-tab label="Import">
        <app-import-tab :bucketId="bucket.id" />
      </cs-page-tab>
      <!-- <cs-page-tab label="Upload">
        <app-upload-tab :bucketId="bucket.id" :prefix="path_key" />
      </cs-page-tab> -->
    </cs-page-tabs>
  </div>
</template>

<script>
import AppFilesTab from '@/components/files/FilesTab.vue';
import AppBucketBreadcrumbs from '@/components/buckets/BucketBreadcrumbs.vue';
import AppImportTab from '@/components/import/ImportTab.vue';
import GetBucket from '@/gql/buckets/GetBucket.gql';
import AppUploadTab from '@/components/upload/UploadTab.vue'

export default {
  components: {
    AppFilesTab,
    AppBucketBreadcrumbs,
    AppImportTab,
    AppUploadTab
  },
  props: {
    provider_id: {
      type: String,
      required: true,
    },
    bucket_name: {
      type: String,
      required: true,
    },
    path_key: {
      type: String,
      required: true,
    },
  },
  apollo: {
    bucket: {
      query: GetBucket,
      variables() {
        return {
          provider_id: this.provider_id,
          name: this.bucket_name,
        };
      },
    },
  },
  methods: {
    refresh() {
      return this.$apollo.queries.bucket.refetch();
    },
  },
};
</script>

<style scoped>
</style>
