<template>
  <div>
    <cs-modal :show="show" class="bp-job-modal" @close="onClose" layout="medium">
      <div slot="header">{{ action.name }}</div>
      <div slot="body">
        <div class="bp-job-modal__description">
          {{ action.description }} (${{ action.billing_price }} per
          {{ action.billing_unit }})
        </div>
        <app-add-progress-bar :step="step" :total-step="totalSteps" />
        <app-run-action-input-output 
          v-show="step === 1" 
          ref="ioParams"
          v-model="firstStepValid"
          :action="action"
          :buckets="buckets"
          @continue="onContinue" 
        />
        <app-run-action-params 
          v-if="step === 2" 
          ref="actionParams"
          v-model="secondStepValid"
          :variables="action.variables"
          :initialValues="parameters"
          @run="onRun"/>
      </div>
      <cs-button
        slot="main"
        v-if="showContinueButton"
        fill="solid"
        :disabled="!firstStepValid || connecting"
        corners="rounded"
        @click="onContinue"
        >
        Continue
      </cs-button>
      <cs-button
        slot="main"
        v-if="!showContinueButton"
        corners="rounded"
        class="bp-continue-button"
        :disabled="disableRunButton"
        @click="onRun">
        Run
      </cs-button>
      <cs-button
        slot="fallback"
        v-if="showBackButton"
        corners="rounded"
        class="bp-continue-button"
        fill="clear"
        @click="onBack"
        >
        Back
      </cs-button>
      <div v-if="connecting" class="bp-job-modal__loading" slot="body">
        <div class="bp-job-modal__loading-background"></div>
        <cs-spinner />
      </div>
    </cs-modal>
  </div>
</template>

<script>
import axios from 'axios';
import AppAddProgressBar from '@/components/general/AddProgressBar.vue';
import AppRunActionInputOutput from '@/components/jobs/RunActionInputOutput.vue';
import AppRunActionParams from '@/components/jobs/RunActionParams.vue';
import ListBuckets from '@/gql/buckets/ListBuckets.gql';

export default {
  components: { AppAddProgressBar, AppRunActionInputOutput, AppRunActionParams },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    action: {
      type: Object,
      required: true,
    },
  },
  apollo: {
    buckets: ListBuckets,
  },
  data() {
    return {
      connecting: false,
      firstStepValid: false,
      secondStepValid: false,
      step: 1,
      totalSteps: this.action.variables && this.action.variables.length ? 2 : 1,
      job: {
        action_id: this.action.id,
        input_path: '',
        output_path: '',
        parameters: {},
      },
      parameters: {},
    };
  },
  computed: {
    hasActionParams() {
      return this.action && this.action.variables && this.action.variables.length > 0;
    },
    showContinueButton() {
      return this.hasActionParams && this.step < this.totalSteps;
    },
    showBackButton() {
      return this.hasActionParams && (this.step > 1);
    },
    disableRunButton() {
      return (!this.firstStepValid && !this.hasActionParams) 
              || (!this.secondStepValid && this.hasActionParams) 
              || this.connecting;
    }
  },
  methods: {
    async onRun() {
      try {
        this.connecting = true;
        if(this.step === 1 && this.$refs['ioParams']) {
          this.job = this.$refs['ioParams'].getInputOutputParams();
        }
        this.job.parameters = this.parameters;
       
        const resp = await axios.post(
          `${process.env.VUE_APP_REST_ENDPOINT}/actions/run`,
          this.job
        );
        this.connecting = false;
        this.$router.push({
          name: 'JobDetail',
          params: {
            id: resp.data,
          },
        });
        this.$emit('close');
      } catch (err) {
        this.$dialog.alert(err.message);
      }
      this.connecting = false;
    },
    onClose() {
      // this.creds = {};
      this.step = 1;
      this.job = {
        action_id: this.action.id,
        input_path: '',
        output_path: '',
        parameters: {},
      };
      this.$emit('close');
    },
    onContinue() {
      this.job = this.$refs['ioParams'].getInputOutputParams();
      this.job.parameters = this.parameters;
      this.step += 1;
    }, 
    onBack() {
      this.parameters = this.$refs['actionParams'] ? this.$refs['actionParams'].getActionParams() : {};
      this.job = 
      this.step -=1;
    }
  },
};
</script>

<style scoped>
.bp-job-modal.cs-modal {
  /* ---cs-modal-width: 550px !important; */
  ---cs-modal-max-height: 90%;
}
.bp-job-modal.cs-modal >>> .cs-modal__popup {
  border: none;
}
.bp-job-modal__vertical-form {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
}
.bp-job-modal__vertical-form >>> .cs-textstyle-label-text {
  font-weight: bold;
}
.bp-job-modal__vertical-form >>> .cs-select__label {
  display: block;
}

.bp-job-modal__loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bp-job-modal__loading,
.bp-job-modal__loading-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.bp-job-modal__loading-background {
  background-color: var(--cs-gray-06);
  opacity: 0.2;
}

.bp-job-modal__description {
  margin-top: 16px;
}
</style>
