<template>
  <div class="bp-billing">
    <app-page-header title="Billing"> </app-page-header>
    <app-gray-card>
      <div class="bp-billing-header">
        <div class="bp-total-cost">
          <h2 class="cs-textstyle-section-heading">
            Total cost ${{totalBalance}}
          </h2>
          <p class="cs-textstyle-paragraph">{{startDateFormatted}} - {{endDateFormatted}}</p>
        </div>
        <div class="bp-date-filters">
          <cs-date-input v-model="startDate" label="Start Date" />
          <cs-date-input v-model="endDate" label="End Date" />
          <cs-button
            class="bp-billing__header-refresh-button bp-refresh-button"
            icon="cs-icons-pull-refresh"
            fill="clear"
            corners="rounded"
            variant="secondary"
            @click="refresh"
          />
        </div>
      </div>
      <b-table
        :items="lines"
        :fields="fields"
        :busy="$apollo.queries.lines.loading"
        outlined
        show-empty
        table-variant="light"
        thead-tr-class="eca-dashboard__table-head-row"
        tbody-tr-class="eca-dashboard__table-row"
      >
        <div slot="table-busy" class="bp-table-empty">
          <cs-spinner />
        </div>
        <div slot="empty" class="bp-table-empty">
          No charges in this period.
        </div>
        <div slot="emptyfiltered" class="bp-table-empty">
          No matching charges in this period.
        </div>
      </b-table>
    </app-gray-card>
  </div>
</template>

<script>
import ListBillingLines from '@/gql/billing/ListBillingLines.gql';
import AppPageHeader from '@/components/general/PageHeader.vue';
import AppGrayCard from '@/components/general/GrayCard.vue';

export default {
  components: {
    AppPageHeader,
    AppGrayCard,
  },
  apollo: {
    lines: {
      query: ListBillingLines,
      variables() {
        return {
          start_date: this.startDate,
          end_date: this.endDate,
        };
      },
    },
  },
  data() {
    return {
      startDate: new Date(this.$moment().subtract('months', 1).unix() * 1000),
      endDate: new Date(),
      fields: [
        {
          label: 'Action',
          key: 'action',
          formatter: (action) => action.name,
        },
        {
          key: 'cost',
          label: 'Cost',
          formatter: (cost) => `$${cost.toFixed(2)}`,
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'count',
          label: 'Job Count',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'quantity',
          label: 'Quantity',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
      ],
    };
  },
  computed: {
    totalBalance() {
      return (
        this.lines &&
        this.lines
          .reduce((acc, a) => {
            return a.cost + acc;
          }, 0)
          .toFixed(2)
      );
    },
    startDateFormatted() {
      return this.formatDate(new Date(this.startDate));
    },
    endDateFormatted() {
      return this.formatDate(new Date(this.endDate));
    }
  },
  methods: {
    refresh() {
      this.$apollo.queries.lines.refetch();
    },
    formatDate(date) {
      return date.toLocaleString('default', {day: 'numeric', month: 'short', year: '2-digit' });
    }
  }
};
</script>

<style scoped>
.bp-billing-header {
  display: flex;
  justify-content: space-between;
  color: var(--cs-gray-07);
}
.bp-date-filters {
  display: flex;
  gap: 16px;
}
button.bp-billing__header-refresh-button.cs-button {
  padding-top: var(--cs-form-input-padding-y);
  padding-bottom: var(--cs-form-input-padding-y);
  align-self: flex-end;
}
button.bp-billing__header-refresh-button.cs-button {
  padding-top: var(--cs-form-input-padding-y);
  padding-bottom: var(--cs-form-input-padding-y);
  align-self: flex-end;
}
.b-table {
  margin-top: 24px;
}
</style>
