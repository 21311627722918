<template>
  <cs-modal
    :show="show"
    class="bp-upload-modal"
    @close="onClose"
    layout="medium"
  >
    <div slot="header">Upload File</div>
    <div slot="body" v-if="!uploading" class="bp-upload__modal-body">
      <div v-if="selectedFile" class="bp-upload__file-preview">
        <img :src="selectedFileIcon" alt="" class="filter-black-to-blue" />
        <div>
          <div>{{ selectedFile.name }}</div>
          <div>{{ selectedFile.size | size }}</div>
        </div>
      </div>
      <i class="cs-icons-next-arrow bp-upload-arrow"></i>
      <div>
        <div>{{ providerId }}/{{ bucketName }}/</div>
        <cs-input v-model="customUploadPath" />
      </div>
    </div>
    <div slot="body" v-if="uploading">
      <cs-spinner />
    </div>
    <div slot="extra">
      <cs-checkbox
        v-model="useAcceleratedUpload"
        label="Use accelerated upload"
        class="bp-upload__checkbox"
        :disabled="uploading"
      />
    </div>
    <cs-button
      slot="main"
      variant="primary"
      fill="solid"
      @click="upload()"
      :disabled="uploading"
      corners="rounded"
      >Upload</cs-button
    >
  </cs-modal>
</template>

<script>
import axios from 'axios';
import { getIconForKey } from '@/services/fileicon';
import GetUploadUrl from '@/gql/upload/GetUploadUrl.gql';
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    selectedFile: {
      type: File,
    },
    providerId: {
      type: String,
    },
    bucketName: {
      type: String,
    },
    uploadPath: {
      type: String,
    },
    bucketId: {
      type: String,
    },
  },
  data() {
    return {
      uploading: false,
      useAcceleratedUpload: true,
      customUploadPath: this.uploadPath,
    };
  },
  methods: {
    async upload() {
      this.uploading = true;
      const uploadUrl = await this.getUploadUrl();
      console.time('upload');
      if (this.useAcceleratedUpload) {
        await this.fastUpload(uploadUrl);
      } else {
        await this.slowUpload(uploadUrl);
      }
      this.uploading = false;
      this.onClose();
      console.timeEnd('upload');
      this.$emit('upload');
      this.$router.push({
        name: 'FileDetail',
        params: {
          provider_id: this.providerId,
          bucket_name: this.bucketName,
          path_key: this.customUploadPath,
        },
        query: {
          accelerated: this.useAcceleratedUpload,
        },
      });
    },
    async fastUpload(uploadUrl) {
      const formData = new FormData();
      formData.append('destination', uploadUrl);
      formData.append('bucket_id', this.bucketId);
      formData.append('path_key', this.customUploadPath);
      formData.append('size', this.selectedFile.size);
      formData.append('file', this.selectedFile);
      const bucketResponse = await axios.post(
        'https://upload.bucketplus.com/upload',
        // 'http://localhost:9222/upload',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      this.$emit('fastUploadStarted');
    },
    async slowUpload(uploadUrl) {
      const bucketResponse = await axios.put(uploadUrl, this.selectedFile, {
        headers: {
          'Content-Type': this.selectedFile.type,
        },
      });
      this.uploading = true;
    },
    async getUploadUrl() {
      const resp = await this.$apollo.query({
        query: GetUploadUrl,
        variables: {
          provider_id: this.providerId,
          bucket_name: this.bucketName,
          path_key: this.customUploadPath,
          content_type: this.selectedFile.type,
        },
        fetchPolicy: 'network-only',
      });
      const uploadUrl = resp.data.url;
      return uploadUrl;
    },
    onClose() {
      this.$emit('close');
    },
  },
  watch: {
    uploadPath(newValue, oldValue) {
      if (newValue.length && !oldValue.length) {
        this.customUploadPath = newValue;
      }
    },
  },
  computed: {
    selectedFileIcon() {
      return getIconForKey(this.selectedFile.name, 40, 'ios-filled');
    },
  },
};
</script>
<style scoped>
.bp-upload__modal-body {
  background-color: var(--cs-gray-01);
  padding: 15px;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  margin-top: 10px;
}
.bp-upload__file-preview {
  display: flex;
  align-items: center;
  gap: 10px;
}
.bp-upload__checkbox >>> .cs-checkbox__label-text {
  font-size: var(--cs-font-size-100);
}
</style>
