import Vue from 'vue';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import VueMoment from 'vue-moment';
import apolloClient from '@/services/apollo';
import VueApollo from 'vue-apollo';
import dialog from '@/plugins/dialog';
import router from './router';

import App from './App.vue';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import 'cs-components';
import 'cs-components/dist/csuite.css';
import '@/assets/css/design-token.css';
import '@/assets/css/style.css';
import '@/assets/css/dialog.css';
import '@/assets/icons/css/styles.css';

import '@/services/esc-modal';

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueApollo);
Vue.use(VueMoment);
Vue.use(dialog);

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
});

Vue.directive('router-link', {
  inserted(el, binding, vnode) {
    el.addEventListener('click', () => {
      vnode.context.$router.push(binding.value);
    });
  },
});

Vue.filter('formatTag', (value) => value.split('_').join(' '));

Vue.filter('size', (value) => {
  if (!value) {
    return '';
  }
  if (value < 1000) {
    return `${value} B`;
  }
  if (value < 1000000) {
    return `${Math.round(value / 1000)} KB`;
  }
  if (value < 1000000000) {
    return `${Math.round(value / 1000000)} MB`;
  }
  return `${Math.round(value / 1000000000)} GB`;
});

new Vue({
  apolloProvider,
  router,
  render: (h) => h(App),
}).$mount('#app');
