<template>
  <div class="bp-bucket-detail-page">
    <cs-spinner v-if="$apollo.queries.bucket.loading" class="eca-spinner" />
    <div v-if="bucket">
      <app-bucket-breadcrumbs />
      <app-page-header :title="bucket.name">
        <app-edit-connection-bucket-button
          :bucket="bucket"
          @bucket-edit-done="onBucketEditDone"
        />
        <app-disconnect-bucket-button
          :bucket="bucket"
          @bucket-disconnected="onBucketDisconnected"
        />
      </app-page-header>
      <cs-page-tabs v-model="tabTransform" class="tabs-without-x-padding">
        <cs-page-tab label="Objects">
          <app-files-tab :bucketId="bucket.id" />
        </cs-page-tab>
        <cs-page-tab label="Import">
          <app-import-tab />
        </cs-page-tab>
        <!-- <cs-page-tab label="Upload">
          <app-upload-tab :bucketId="bucket.id" />
        </cs-page-tab> -->
        <cs-page-tab label="CDN">
          <app-distributions-tab
            :provider_id="provider_id"
            :bucket_name="bucket_name"
          />
        </cs-page-tab>
        <!-- <cs-page-tab label="Jobs">
          <app-job-history />
        </cs-page-tab> -->
        <!-- <cs-page-tab label="Triggers">
          <app-triggers-tab :bucketId="bucket.id" />
        </cs-page-tab> -->
        <cs-page-tab label="Details">
          <app-bucket-detail-tab :bucket="bucket" />
        </cs-page-tab>
      </cs-page-tabs>
    </div>
  </div>
</template>

<script>
import GetBucket from '@/gql/buckets/GetBucket.gql';

import AppBucketBreadcrumbs from '@/components/buckets/BucketBreadcrumbs.vue';
import AppPageHeader from '@/components/general/PageHeader.vue';
import AppDisconnectBucketButton from '@/components/buckets/DisconnectBucketButton.vue';
import AppEditConnectionBucketButton from '@/components/buckets/EditConnectionBucketButton.vue';

import AppFilesTab from '@/components/files/FilesTab.vue';
import AppImportTab from '@/components/import/ImportTab.vue';
import AppUploadTab from '@/components/upload/UploadTab.vue';
import AppDistributionsTab from '@/components/distributions/DistributionsTab.vue';

import AppTriggersTab from '@/components/triggers/TriggersTab.vue';
import AppBucketDetailTab from '@/components/buckets/BucketDetailTab.vue';

export default {
  components: {
    AppBucketBreadcrumbs,
    AppPageHeader,
    AppDisconnectBucketButton,
    AppEditConnectionBucketButton,
    AppFilesTab,
    AppImportTab,
    AppUploadTab,
    AppDistributionsTab,
    AppTriggersTab,
    AppBucketDetailTab,
  },
  props: {
    provider_id: {
      type: String,
      required: true,
    },
    bucket_name: {
      type: String,
      required: true,
    },
  },
  apollo: {
    bucket: {
      query: GetBucket,
      variables() {
        return {
          provider_id: this.provider_id,
          name: this.bucket_name,
        };
      },
    },
  },
  data() {
    return {
      tab: this.getTab(),
    };
  },
  computed: {
    tabTransform: {
      get() {
        return this.getTab();
      },
      set(newValue) {
        this.tab = newValue;
      },
    },
  },
  watch: {
    tab(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.goToCurrentRoute();
      }
    },
  },
  mounted() {
    // Go to index(0) if there is no tab query param
    const { tab } = this.$route.query;
    if (!tab) this.goToCurrentRoute();
  },
  methods: {
    getTab() {
      const { tab: value } = this.$route.query;
      const accpetedValues = [0, 1, 2, 3];
      const tabValue = accpetedValues.includes(Number(value))
        ? Number(value)
        : 0;
      return tabValue;
    },
    onBucketDisconnected() {
      this.$router.push('/buckets');
    },
    onBucketEditDone() {
      this.$apollo.queries.bucket.refetch();
    },
    goToCurrentRoute() {
      this.$router.replace({
        name: 'BucketDetail',
        params: {
          provider_id: this.provider_id,
          bucket_name: this.bucket_name,
        },
        query: { tab: this.tab },
      });
    },
  },
};
</script>

<style scoped></style>
