<template>
  <app-gray-card>
    <app-list-control-bar
      v-model="searchQuery"
      placeholder="Filter distributions"
      @refresh="refresh()"
    >
      <cs-button corners="rounded" @click="showCreateDistributionModal = true"
        >Create Distribution</cs-button
      >
    </app-list-control-bar>
    <b-table
      :items="distributions"
      :fields="fields"
      :filter="searchQuery"
      hover
      outlined
      selectable
      table-variant="light"
      show-empty
      :busy="$apollo.queries.distributions.loading"
      select-mode="single"
      thead-tr-class="eca-dashboard__table-head-row"
      tbody-tr-class="eca-dashboard__table-row"
      @row-selected="onDistributionSelected"
    >
      <div slot="table-busy" class="bp-table-empty">
        <cs-spinner />
      </div>
      <div slot="empty" class="bp-table-empty">
        You haven't added any distributions yet.
      </div>
      <div slot="emptyfiltered" class="bp-table-empty">
        No matching distributions.
      </div>
      <template v-slot:cell(created_at)="data">
        <app-display-date-time :value="data.item.created_at" />
      </template>
      <template v-slot:cell(action)="data">
        <i :id="`${data.item.id}`" class="cs-icons-options"></i>
        <b-popover
          :ref="`${data.item.id}-menu`"
          :target="`${data.item.id}`"
          placement="bottom"
          triggers="blur hover"
          custom-class="bg-dark"
        >
          <div>
            <div class="bp-service-list__menu-body">
              <div
                class="bp-service-list__menu-item"
                @click.stop="purge(data.item)"
              >
                Purge
              </div>
              <div
                class="bp-service-list__menu-item"
                @click.stop="remove(data.item)"
              >
                Delete
              </div>
            </div>
          </div>
        </b-popover>
      </template>
    </b-table>
    <app-create-distribution-modal
      :provider_id="provider_id"
      :bucket_name="bucket_name"
      :show="showCreateDistributionModal"
      @success="refresh()"
      @close="showCreateDistributionModal = false"
    />
  </app-gray-card>
</template>
<script>
import ListDistributions from '@/gql/distributions/ListDistributions.gql';
import AppListControlBar from '@/components/general/ListControlBar.vue';
import AppGrayCard from '@/components/general/GrayCard.vue';
import AppDisplayDateTime from '@/components/general/DisplayDateTime.vue';
import AppCreateDistributionModal from '@/components/distributions/CreateDistributionModal.vue';

import {
  purgeDistribution,
  deleteDistribution,
} from '@/services/confirm-delete';

export default {
  components: {
    AppListControlBar,
    AppGrayCard,
    AppDisplayDateTime,
    AppCreateDistributionModal,
  },
  props: {
    provider_id: {
      type: String,
      required: true,
    },
    bucket_name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showCreateDistributionModal: false,
      searchQuery: '',
      fields: [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'path',
          label: 'Root Path',
          formatter: (value) => value || '(bucket root)',
        },
        {
          key: 'domain',
          label: 'Default Domain',
          formatter: (value, key, item) => {
            return `https://${item.id}.b-cdn.net`;
          },
        },
        {
          key: 'created_at',
          label: 'Created',
        },
        {
          key: 'action',
          label: '',
        },
      ],
    };
  },
  apollo: {
    distributions: {
      query: ListDistributions,
      variables() {
        return {
          provider_id: this.provider_id,
          bucket_name: this.bucket_name,
        };
      },
    },
  },
  methods: {
    refresh() {
      this.$apollo &&
        this.$apollo.queries &&
        this.$apollo.queries.distributions &&
        this.$apollo.queries.distributions.refetch();
    },
    onDistributionSelected(rows) {
      const distribution = rows[0];
      if (distribution) {
        this.$router.push({
          name: 'DistributionDetail',
          params: {
            id: distribution.id,
          },
          query: {
            tab: this.$route.query.tab,
          },
        });
      }
    },
    async purge(distribution) {
      const response = await purgeDistribution(distribution);
      if (response) this.$dialog.alert('Distribution purged');
    },
    async remove(distribution) {
      const response = await deleteDistribution(distribution);
      if (response) this.refresh();
    },
  },
  beforeMount() {
    this.refresh();
  },
};
</script>
