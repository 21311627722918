<template>
  <app-gray-card>
    <div class="row">
      <div class="col-md-6 flex-vertical-gap">
        <app-distribution-basic-card :distribution="distribution" @on-edit-settings="emitSettingsEditted"/>
        <app-distribution-edge-card :distribution="distribution" @on-edit-settings="emitSettingsEditted"/>
      </div>
      <div class="col-md-6 flex-vertical-gap">
        <app-distribution-optimizer-card :distribution="distribution" @on-edit-settings="emitSettingsEditted"/>
        <app-distribution-cache-card :distribution="distribution" @on-edit-settings="emitSettingsEditted" />
      </div>
    </div>
  </app-gray-card>
</template>
<script>
import AppGrayCard from '@/components/general/GrayCard.vue';
import AppDistributionBasicCard from '@/components/distributions/DistributionBasicCard.vue';
import AppDistributionEdgeCard from '@/components/distributions/DistributionEdgeCard.vue';
import AppDistributionCacheCard from '@/components/distributions/DistributionCacheCard.vue';
import AppDistributionOptimizerCard from '@/components/distributions/DistributionOptimizerCard.vue';

export default {
  components: {
    AppGrayCard,
    AppDistributionBasicCard,
    AppDistributionEdgeCard,
    AppDistributionCacheCard,
    AppDistributionOptimizerCard,
  },
  props: {
    distribution: {
      type: Object,
      required: true,
    },
  },
  methods: {
    emitSettingsEditted() {
      this.$emit('on-edit-settings');
    }
  }
};
</script>
<style scoped>
.flex-vertical-gap {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>
