<template>
  <div class="bp-sidebar">
    <div class="bp-sidebar__logo-container">
      <img alt="Bucket Plus logo" :src="logo" class="bp-sidebar__logo" />
    </div>
    <!-- Services -->
    <ul>
      <li v-for="(link, index) in navLinks" :key="index">
        <router-link
          :to="serviceRoute(link)"
          :class="isActiveRoute(link) ? 'bp-sidebar__active-items' : ''"
          class="bp-sidebar__none-active-items"
        >
          <span class="bp-sidebar__items">
            <i v-if="link.icon" :class="link.icon" />
            <img
              v-else-if="link.svg"
              class="bp-sidebar__item-icon-svg"
              :src="link.svg"
              :alt="`${link.text} icon`"
            />
            {{ link.text }}
          </span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
const logo = require('@/assets/images/BP-logo-blue.svg');
const serviceIcon = require('@/assets/images/Service.svg');
const bucketsIcon = require('@/assets/images/Buckets.svg');

export default {
  props: {},
  data() {
    return {
      logo,
      navLinks: [
        // {
        //   text: 'Dashboard',
        //   path: '',
        //   name: 'Dashboard',
        //   icon: 'cs-icons-home-filled',
        // },
        {
          text: 'Buckets',
          path: 'buckets',
          name: 'BucketList',
          icon: 'cs-icons-data-filled',
        },
        {
          text: 'Jobs',
          path: 'jobs',
          name: 'JobHistory',
          icon: 'cs-icons-check-filled',
        },
        {
          text: 'Project Keys',
          path: 'keys',
          name: 'ProjectKeyList',
          icon: 'cs-icons-remote-filled',
        },
        {
          text: 'CDN',
          path: 'distributions',
          name: 'DistributionsList',
          icon: 'cs-icons-data-filled',
        },
        // {
        //   text: 'Users',
        //   path: 'users',
        //   name: 'UserList',
        //   icon: 'cs-icons-profile-filled',
        // },
        {
          text: 'Billing',
          path: 'billing',
          name: 'Billing',
          icon: 'cs-icons-coins-filled',
        },
      ],
    };
  },
  methods: {
    isActiveRoute(link) {
      if (!this.$route.name) return false;
      return this.$route.name.includes(link.name);
    },
    serviceRoute(link) {
      const path = link.extra ? `${link.path}/${link.extra}` : link.path;
      return `/${path}`;
    },
  },
};
</script>

<style scoped>
.bp-sidebar {
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #040521;
  position: relative;
}
.bp-sidebar__logo-container {
  /* height: 65px; */
  border-bottom: 1px solid var(--cs-gray-05);
  padding-left: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.bp-sidebar__logo {
  /* height: 20px; */
  width: 100px;
  padding: 16px 0;
}

ul {
  display: flex;
  align-items: center;
  padding-inline-start: 0;
  flex-direction: column;
  margin: 0px;
}
a {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: white;
}
a:hover {
  color: var(--cs-gray-00);
}
i,
.bp-sidebar__item-icon-svg {
  margin-right: 10px;
  font-size: 22px;
}
.bp-sidebar__item-icon-svg {
  width: 20px;
  margin-top: -8px;
}
li {
  list-style-type: none;
  width: 100%;
}
.bp-sidebar__active-items {
  background-color: var(--cs-primary-base) !important;
  color: var(--cs-gray-00) !important;
  border-color: inherit !important;
}
.bp-sidebar__sub-active-items {
  background-color: var(--cs-primary-dark) !important;
  color: var(--cs-gray-00) !important;
  border-color: inherit !important;
}
.bp-sidebar__items {
  padding: 10px 20px;
  font-weight: 400;
  font-size: 14px;
}
.bp-sidebar__sub-items {
  padding: 10px 0px 10px 40px;
  font-weight: 400;
  margin-left: 15px;
  font-size: 12px;
}
.bp-sidebar__none-active-items {
  background: transparent;
}
.bp-sidebar__bottom-active-items {
  background: var(--cs-primary-base);
}
</style>
