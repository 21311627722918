<template>
  <cs-card>
    <div slot="header" class="bp-card-header">
      <div class="cs-textstyle-item-heading">Edge Rules</div>
      <app-distribution-edge-edit :distribution="distribution" @success="onSuccess"/>
    </div>
    <div slot="body">
      <hr class="bp-light-line">
      <app-simple-card-table :items="items" />
    </div>
  </cs-card>
</template>
<script>
import AppDistributionEdgeEdit from '@/components/distributions/DistributionEdgeEdit.vue';
import AppSimpleCardTable from '@/components/distributions/SimpleCardTable.vue';

export default {
  components: {
    AppDistributionEdgeEdit,
    AppSimpleCardTable,
  },
  props: {
    distribution: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      items: [
        {
          label: 'North America',
          value: this.distribution.enable_geo_zone_us ? 'Enabled' : 'Disabled',
        },
        {
          label: 'Europe',
          value: this.distribution.enable_geo_zone_eu ? 'Enabled' : 'Disabled',
        },
        {
          label: 'Asia and Oceania',
          value: this.distribution.enable_geo_zone_asia
            ? 'Enabled'
            : 'Disabled',
        },
        {
          label: 'South America',
          value: this.distribution.enable_geo_zone_sa ? 'Enabled' : 'Disabled',
        },
        {
          label: 'Africa',
          value: this.distribution.enable_geo_zone_af ? 'Enabled' : 'Disabled',
        },
      ],
    };
  },
  methods: {
    onSuccess() {
      this.$emit('on-edit-settings');
    }
  }
};
</script>
