<template>
  <div>
    <div class="bp-trigger-filter-row">
      <cs-select
        :options="options"
        class="bp-trigger-filter-select"
        v-model="filter.criteria"
        @change="$emit('input', filter)"
      >
      </cs-select>
      <cs-input
        class="bp-trigger-filter-input"
        v-model="filter.value"
        @change="$emit('input', filter)"
      >
      </cs-input>
      <i @click="$emit('remove')" class="cs-icons-delete" />
    </div>
    <div class="trigger-and">and</div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      filter: this.value,
      options: [
        {
          label: 'File Extension equals',
          value: 'extension_equals',
        },
        {
          label: 'File Extension includes',
          value: 'extension_includes',
        },
        {
          label: 'File Name equals',
          value: 'name_equals',
        },
        {
          label: 'File Name includes',
          value: 'name_includes',
        },
        {
          label: 'File Size is greater than',
          value: 'size_greater',
        },
        {
          label: 'File Size is less than',
          value: 'size_less',
        },
        {
          label: 'A metadata tag includes',
          value: 'metadata_include',
        },
        {
          label: 'A metadata tag equals',
          value: 'metadata_equals'
        },
      ],
    };
  },
}
</script>
<style scoped>
.bp-trigger-filter-row {
  display: flex;
  gap: 5px;
  align-items: center;
}
.bp-trigger-filter-and {
  text-align: center;
}
.bp-trigger-filter-select {
}
.bp-trigger-filter-input {
  flex: 1;
}
.trigger-and {
  text-align: center;
  color: var(--cs-gray-04);
}
</style>
