<template>
  <div>
    <div class="bp-job-logs">
      <div class="ecs-ss-build-detail__extras-content">
        <div class="ecs-ss-build-detail-timeline">
          <div v-if="!logs">Loading...</div>
          <div v-if="logs && !logs.length">No logs</div>
          <div class="outer" v-if="logs && logs.length">
            <div
              v-for="(log, i) in logs"
              :key="i"
              class="ecs-ss-build-detail-timeline-item"
            >
              <span class="ecs-ss-build-detail-timeline-number">{{
                i + 1
              }}</span>
              <div
                class="cs-textstyle-paragraph-small ecs-ss-build-detail-timeline-item-content"
              >
                <app-display-date-time
                  v-if="log.created_at"
                  :value="log.created_at"
                  display-format="h:mm:ss A:"
                />
                <span v-if="log.message">{{ log.message }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ListJobLogs from '@/gql/jobs/ListJobLogs.gql';
import AppDisplayDateTime from '@/components/general/DisplayDateTime.vue';

export default {
  components: {
    AppDisplayDateTime,
  },
  props: {
    jobId: {
      type: String,
      required: true,
    },
  },
  apollo: {
    logs: {
      query: ListJobLogs,
      variables() {
        return {
          id: this.jobId,
        };
      },
    },
  },
  methods: {
    refresh() {
      this.$apollo.queries.logs.refetch();
    },
  },
};
</script>
<style scoped>
.bp-job-logs {
  background-color: black;
  color: white;
  font-family: monospace;
  padding: 10px;
  border-radius: 3px;
}
.bp-job-log-timestamp {
  color: grey;
}

.ecs-ss-build-detail__info {
  display: flex;
  flex-direction: row;
  padding: 20px;
  justify-content: flex-start;
  border: 1px solid var(--cs-gray-02);
  background-color: var(--cs-gray-01);
  border-radius: 5px;
  margin-bottom: 50px;
  margin-top: 40px;
  gap: 32px;
}
.ecs-ss-build-detail__info-content {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 300px;
}
.ecs-ss-build-detail__extras {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ecs-ss-build-detail__extras-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.ecs-ss-build-detail__extras-content {
  flex: 1;
  background-color: var(--cs-gray-07) !important;
  color: var(--cs-gray-03) !important;
  overflow: auto;
  padding-bottom: 75px;
}
/* ecs-ss-build-detail-Timeline Container */
.ecs-ss-build-detail-timeline {
  padding: 32px;
  overflow: auto;
  overflow-x: hidden;
  position: relative;
}
/* Outer Layer with the ecs-ss-build-detail-timeline border */
.outer {
  border-left: 1px solid var(--cs-gray-04);
  padding-top: 20px;
  padding-bottom: 10px;
}
.ecs-ss-build-detail-timeline-item {
  margin-bottom: 7px;
}
.ecs-ss-build-detail-timeline-item-content {
  margin-left: 21px;
  color: var(--cs-gray-03);
}
.ecs-ss-build-detail-timeline-number {
  position: absolute;
  color: var(--cs-gray-00);
  font-size: 8px;
  border-radius: 50%;
  background-color: var(--cs-gray-05);
  margin-left: -8px;
  margin-top: 3px;
  width: 14px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
