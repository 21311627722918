<template>
  <div class="bp-file-embed">
    <img class="file-embed-preview-image" v-if="isImg" :src="src" />
    <cs-video-player
      class="file-embed-preview"
      v-else-if="isVideo"
      :src="src"
      controls
    />
    <cs-audio-player
      class="file-embed-preview-video"
      v-else-if="isAudio"
      :src="src"
      controls
    />
    <iframe class="file-embed-preview-pdf" v-else-if="isPdf" :src="src" />
    <cs-empty-state v-else description="No preview available" />
  </div>
</template>
<script>
export default {
  props: {
    file: {
      type: Object,
      required: true,
    },
    src: {
      type: String,
      required: true,
    },
  },
  computed: {
    isImg() {
      const val =
        this.file.key.endsWith('.jpg') ||
        this.file.key.endsWith('.jpeg') ||
        this.file.key.endsWith('.png') ||
        this.file.key.endsWith('.gif') ||
        this.file.mime_type.startsWith('image/');
      return val;
    },
    isVideo() {
      const val =
        this.file.key.endsWith('.mp4') ||
        this.file.key.endsWith('.mov') ||
        this.file.key.endsWith('.m4v') ||
        this.file.mime_type.startsWith('video/');
      return val;
    },
    isAudio() {
      const val =
        this.file.key.endsWith('.mp3') ||
        this.file.key.endsWith('.m4a') ||
        this.file.key.endsWith('.aac') ||
        this.file.key.endsWith('.ogg') ||
        this.file.mime_type.startsWith('audio/');
      return val;
    },
    isPdf() {
      const val =
        this.file.key.endsWith('.pdf') ||
        this.file.mime_type.startsWith('application/pdf');
      return val;
    },
  },
};
</script>
<style scoped>
.bp-file-embed {
}
.bp-file-embed > * {
  border-radius: 7px;
}
.file-embed-preview-image {
  max-width: 800px;
  max-height: 400px;
}
.file-embed-preview-video {
  max-width: 800px;
  width: 100%;
}
.file-embed-preview-pdf {
  width: 100%;
  min-height: 600px;
}
</style>
