<template>
  <cs-form class="bp-job-modal__vertical-form">
    <cs-select
      required
      label="Input Bucket"
      disabled
      v-model="job.input_bucket_id"
      :options="bucketOptions"
      v-if="action.input_type != 'other' || !bucketOptions"
    />
    <cs-input
      required
      label="Input Path"
      :disabled="!!defaultInputPath"
      v-model="job.input_path"
      v-if="action.input_type != 'other'"
    />
    <cs-select
      required
      label="Output Bucket"
      v-model="job.output_bucket_id"
      :options="bucketOptions"
      :disabled="action.input_type == 'other' || !bucketOptions"
    />
    <cs-input required label="Output Path" v-model="job.output_path" />
  </cs-form>
</template>

<script>
export default {
  props: {
    action: {
      type: Object,
      required: true,
    },
    buckets: {
      type: Array,
      required: true,
    },
  },
  computed: {
    validForm() {
      const formProps = ['output_path', 'input_bucket_id', 'output_bucket_id'];
      if (this.action.input_type != 'other') formProps.push('input_path');
      const values = formProps.filter((prop) => this.job[prop]);
      return values.length === formProps.length;
    },
    bucketOptions() {
      return (
        this.buckets &&
        this.buckets.map((b) => {
          return {
            value: b.id,
            label: `${b.provider.id}/${b.name}`,
          };
        })
      );
    },
  },
  data() {
    const isFolder =
      this.$route.params.path_key && this.$route.params.path_key.endsWith('/');
    const defaultInputPath = this.$route.params.path_key;
    const defaultOutputPath =
      (isFolder ? this.$route.params.path_key : '') +
      this.action.default_destination_path;
    return {
      defaultInputPath: defaultInputPath,
      connecting: false,
      job: {
        action_id: this.action.id,
        input_path: this.$route.params.path_key,
        output_path: defaultOutputPath,
        parameters: {},
      },
    };
  },
  methods: {
    setDefaultBucketValues() {
      if (this.buckets && this.buckets.length) {
        const currentBucket = this.buckets.find(
          (b) =>
            b.provider.id == this.$route.params.provider_id &&
            b.name == this.$route.params.bucket_name
        );
        this.$set(this.job, 'input_bucket_id', currentBucket.id);
        this.$set(this.job, 'output_bucket_id', currentBucket.id);
      }
    },
    getInputOutputParams() {
      return this.job;
    },
  },
  watch: {
    validForm() {
      this.$emit('input', this.validForm);
    },
    buckets() {
      this.setDefaultBucketValues();
    },
  },
  beforeMount() {
    this.setDefaultBucketValues();
  },
};
</script>
