<template>
  <div>
    <cs-button
      fill="outline"
      @click="showModal = true"
      corners="rounded"
      size="small"
      >Edit</cs-button
    >
    <cs-modal
      :show="showModal"
      class="bp-modal"
      @close="showModal = false"
      layout="large"
    >
      <div slot="header">Edit CDN Settings: Edge Rules for {{distribution.id}}</div>
      <div slot="body">
        <div class="cs-textstyle-section-heading bp-header">
          Regions
        </div>
        <cs-form class="bp-modal__vertical-form">
          <div class="bp-toggle-row">
          <cs-toggle
            label-checked="North America"
            label-unchecked="North America"
            v-model="options.enable_geo_zone_us"
          />
          <cs-toggle label-checked="Europe"
          label-unchecked="Europe" v-model="options.enable_geo_zone_eu" />
          <cs-toggle
            label-checked="Asia and Oceania"
            label-unchecked="Asia and Oceania"
            v-model="options.enable_geo_zone_asia"
          />
        </div>
        <div class="bp-toggle-row">
          <cs-toggle
            label-checked="South America"
            label-unchecked="South America"
            v-model="options.enable_geo_zone_sa"
          />
          <cs-toggle label-checked="Africa"
          label-unchecked="Africa"
           v-model="options.enable_geo_zone_af" />
          </div>
        </cs-form>
        <cs-alert
          v-if="errorTitle"
          :title="errorTitle"
          :content="errorDescription"
          variant="danger"
        />
      </div>
      <cs-button
        slot="main"
        variant="primary"
        @click="save"
        fill="solid"
        :disabled="saving"
        corners="rounded"
        >Apply</cs-button
      >
      <div v-if="saving" class="bp-modal__loading" slot="body">
        <div class="bp-modal__loading-background"></div>
        <cs-spinner />
      </div>
    </cs-modal>
  </div>
</template>

<script>
import UpdateDistributionEdge from '@/gql/distributions/UpdateDistributionEdge.gql';

export default {
  components: {},
  props: {
    distribution: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      saving: false,
      errorTitle: null,
      errorDescription: null,
      showModal: false,
      options: {
        id: this.distribution.bunny_id,
        enable_geo_zone_us: this.distribution.enable_geo_zone_us,
        enable_geo_zone_eu: this.distribution.enable_geo_zone_eu,
        enable_geo_zone_asia: this.distribution.enable_geo_zone_asia,
        enable_geo_zone_sa: this.distribution.enable_geo_zone_sa,
        enable_geo_zone_af: this.distribution.enable_geo_zone_af,
      },
    };
  },
  methods: {
    async save() {
      try {
        this.saving = true;
        this.options.enable_geo_zone_us =
          this.options.enable_geo_zone_us || false;
        this.options.enable_geo_zone_eu =
          this.options.enable_geo_zone_eu || false;
        this.options.enable_geo_zone_asia =
          this.options.enable_geo_zone_asia || false;
        this.options.enable_geo_zone_sa =
          this.options.enable_geo_zone_sa || false;
        this.options.enable_geo_zone_af =
          this.options.enable_geo_zone_af || false;
        const resp = await this.$apollo.mutate({
          mutation: UpdateDistributionEdge,
          variables: this.options,
        });
        this.saving = false;
        this.$emit('success');
        this.errorTitle = null;
        this.errorDescription = null;
        this.showModal=false
      } catch (err) {
        this.saving = false;
        this.errorTitle = err.message;
        this.errorDescription =
          err.graphQLErrors[0].extensions.exception.description;
      }
      this.saving = false;
    },
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.bp-modal.cs-modal {
  /* ---cs-modal-width: 550px !important; */
  ---cs-modal-max-height: 90%;
}
.bp-modal.cs-modal >>> .cs-modal__popup {
  border: none;
}
.bp-modal__vertical-form {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
}
.bp-modal__vertical-form >>> .cs-textstyle-label-text {
  font-weight: bold;
}
.bp-modal__vertical-form >>> .cs-select__label {
  display: block;
}

.bp-modal__loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bp-modal__loading,
.bp-modal__loading-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.bp-modal__loading-background {
  background-color: var(--cs-gray-06);
  opacity: 0.2;
}
.bp-toggle-row{
  display: flex;
  flex-direction: row;
  gap: 20%
}

.cs-toggle {
  --cs-toggle-color: var(--cs-success-base) !important;
}
* >>> .cs-toggle__label {
  flex-direction: row-reverse;
}
* >>> .cs-toggle__label-text {
  margin-left: 0px !important;
  margin-right: 5px;
  font-weight: 600;
  font-size: 16px;
}

.bp-header{
  margin-top: 16px;
}
</style>
