<template>
  <div class="job-info">
    <div class="cs-textstyle-section-heading bp-job-info-title" v-if="title">
      {{title}}
    </div>
    <cs-card>
      <div slot="body">
        <div v-for="(info, index) in data" :key="index" class="job-info-block">
          <div class="cs-textstyle-paragraph-small-bold info-label">
            {{ info.label }}
          </div>

          <cs-tag v-if="info.isStatus" :class="info.value">{{
            info.value
          }}</cs-tag>
          <app-file-card
            v-else-if="info.isFile"
            :bucket="info.bucket"
            :path_key="info.path_key"
            :available="info.available"
          />

          <div v-else class="cs-textstyle-paragraph info-value">
            <i v-if="info.iconLeft" :class="info.iconLeft"></i>

            <app-display-date-time
              v-if="info.type === 'datetime'"
              :value="info.value"
              utc
              display-format="D MMM YYYY, h:mm A (UTC)"
            />
            <template v-else>{{ info.value }}</template>

            <i
              v-if="info.icon"
              :class="info.icon"
              class="right-icon"
              @click="info.iconAction"
            ></i>
          </div>

        </div>
        <div v-if="customData && customData.length > 0" class="bp-custom-data">
          <b-table
            :items="customData"
            :fields="fields"
            outlined
            hover
            class="bp-params-table"
            table-variant="light"
            tbody-tr-class="eca-dashboard__table-row small-padding"
          >
            <!-- Name -->
            <template v-slot:cell(label)="data">
              <div>{{ data.item.label }}</div>
            </template>
            <!-- Value -->
            <template v-slot:cell(value)="data">
              <div>{{ data.item.value }}</div>
            </template>
          </b-table>
        </div>
      </div>
    </cs-card>
  </div>
</template>

<script>
import AppFileCard from '@/components/files/FileCard.vue';
import AppDisplayDateTime from '@/components/general/DisplayDateTime.vue';

export default {
  components: {
    AppFileCard,
    AppDisplayDateTime,
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    data: {
      type: Array,
      required: true,
    },
    customData: {
      type: Array, 
      required: false,
    },
  },
  data() {
    const thClass = 'eca-dashboard__table-head small-padding';
    return {
      fields: [
        {
          key: 'label',
          label: 'Name',
          thClass: thClass,
        },
        {
          key: 'value',
          label: 'Value',
          thClass: thClass  ,
        }
      ],
    }
  }
};
</script>

<style scoped>
.job-info {
  width: 100%;
  box-sizing: border-box;
}
.info-label {
  color: var(--cs-gray-06);
  margin-bottom: 8px;
}
.info-value {
  color: var(--cs-gray-05);
}
.info-value i.right-icon {
  color: var(--cs-primary-base);
}
.job-info-block {
  margin-bottom: 20px;  
}
.bp-job-info-title {
  color: var(--cs-gray-06);
  margin-bottom: 16px;
}
</style>
