<template>
  <span
    v-if="value"
    class="bp-display-date-time__value"
    v-b-tooltip.hover
    :title="fullDate"
    >{{ displayDate }}
  </span>
</template>
<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    utc: {
      type: Boolean,
      default: false,
    },
    displayFormat: {
      type: String,
      default: '',
    },
  },
  computed: {
    momentDate() {
      if (!this.value) return null;
      return this.$moment(this.value);
    },
    displayDate() {
      let momentDate = this.momentDate;
      if (!momentDate) return '';
      if (this.utc) momentDate = momentDate.utc();
      if (this.displayFormat) return momentDate.format(this.displayFormat);
      return momentDate.from();
    },
    fullDate() {
      const momentDate = this.momentDate;
      if (!momentDate) return '';
      return momentDate.format('DD MMM YYYY, hh:mm:ss A Z');
    },
  },
};
</script>
