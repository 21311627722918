<template>
  <div>
    <cs-spinner v-if="$apollo.queries.trigger.loading" />
    <div v-if="trigger">
      <app-page-header :title="trigger.action.name">
        <cs-button variant="danger" corners="rounded" @click="removeTrigger()"
          >Delete</cs-button
        >
      </app-page-header>
      <cs-alert
        v-if="trigger.bucket.provider_id != 'aws'"
        title="Triggers Delayed"
        content="This bucket provider does not support instant trigger actions. Triggers run once a day."
        variant="warning"
      >
      </cs-alert>
      <app-gray-card>
        <div class="row trigger-detail__row">
          <div class="col-md-3">
            <h5>When:</h5>
          </div>
          <div class="col-md-9">
            <div>A file is added to this bucket.</div>
          </div>
        </div>
        <div class="row trigger-detail__row">
          <div class="col-md-3">
            <h5>If:</h5>
          </div>
          <div class="col-md-9">
            <div>The following conditions are all true:</div>
            <div v-for="condition of trigger.conditions" :key="condition.key">
              {{ condition.key }}: {{ condition.value }}
            </div>
          </div>
        </div>
        <div class="row trigger-detail__row">
          <div class="col-md-3">
            <h5>Then:</h5>
          </div>
          <div class="col-md-9">
            <div>Run {{ trigger.action.name }}</div>
          </div>
        </div>
        <div class="row trigger-detail__row">
          <div class="col-md-3">
            <h5>With Parameters:</h5>
          </div>
          <div class="col-md-9">
            <div v-for="jp of trigger.job_parameters" :key="jp.key">
              {{ jp.key }}: {{ jp.value }}
            </div>
          </div>
        </div>
      </app-gray-card>
      <div class="row">
        <div class="col-md-4"></div>
        <div class="col-md-4"></div>
        <div class="col-md-4"></div>
      </div>
    </div>
  </div>
</template>
<script>
import GetTrigger from '@/gql/triggers/GetTrigger.gql';
import RemoveTrigger from '@/gql/triggers/RemoveTrigger.gql';
import AppPageHeader from '@/components/general/PageHeader.vue';
import AppGrayCard from '@/components/general/GrayCard.vue';

export default {
  components: {
    AppPageHeader,
    AppGrayCard,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  apollo: {
    trigger: {
      query: GetTrigger,
      variables() {
        return {
          id: this.id,
        };
      },
    },
  },
  methods: {
    async removeTrigger() {
      const confirmPurge = await this.$dialog.delete({
        message: 'Are you sure you wish to remove this trigger?',
        deleteLabel: 'Remove',
      });
      if (confirmPurge) {
        await this.$apollo.mutate({
          mutation: RemoveTrigger,
          variables: {
            id: this.id,
          },
        });
        this.$router.back();
      }
    },
  },
};
</script>
<style scoped>
.trigger-detail__row {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: solid 1px var(--cs-gray-02);
}
.trigger-detail__row:last-child {
  padding-bottom: none;
  margin-bottom: 0px;
  border-bottom: 0px;
}
</style>
