<template>
  <app-gray-card>
    <app-list-control-bar
      placeholder="Filter domains"
      @refresh="refresh()"
      v-model="searchQuery"
    >
      <app-distribution-hostname-add
        :distribution="distribution"
        @added="refresh()"
      />
    </app-list-control-bar>
    <b-table
      :items="hostnames"
      :filter="searchQuery"
      :fields="fields"
      :busy="$apollo.queries.hostnames.loading"
      outlined
      show-empty
      thead-tr-class="eca-dashboard__table-head-row"
      tbody-tr-class="eca-dashboard__table-row"
    >
      <div slot="table-busy" class="bp-table-empty">
        <cs-spinner />
      </div>
      <div slot="empty" class="bp-table-empty">No domains setup.</div>
      <div slot="emptyfiltered" class="bp-table-empty">
        No matching domains.
      </div>
      <template v-slot:cell(has_certificate)="data">
        <app-provision-certificate-button
          :hostname="data.item"
          :distribution_id="distribution.bunny_id"
          @provisioned="refresh()"
        />
      </template>
      <template v-slot:cell(action)="data">
        <i
          v-if="!data.item.is_system_hostname"
          :id="`${data.item.id}`"
          class="cs-icons-options"
        ></i>
        <b-popover
          v-if="!data.item.is_system_hostname"
          :ref="`${data.item.id}-menu`"
          :target="`${data.item.id}`"
          placement="bottom"
          triggers="blur hover"
          custom-class="bg-dark"
        >
          <div>
            <div class="bp-service-list__menu-body">
              <div
                v-if="!data.item.is_system_hostname"
                class="bp-service-list__menu-item"
                @click.stop="remove(data.item)"
              >
                Delete
              </div>
            </div>
          </div>
        </b-popover>
      </template>
    </b-table>
  </app-gray-card>
</template>
<script>
import AppDistributionHostnameAdd from '@/components/distributions/DistributionHostnameAdd.vue';
import ListHostnames from '@/gql/distributions/ListHostnames.gql';
import AppListControlBar from '@/components/general/ListControlBar.vue';
import AppGrayCard from '@/components/general/GrayCard.vue';
import AppProvisionCertificateButton from '@/components/distributions/ProvisionCertificateButton.vue';

import { deleteDistributionHostname } from '@/services/confirm-delete';

export default {
  components: {
    AppListControlBar,
    AppGrayCard,
    AppDistributionHostnameAdd,
    AppProvisionCertificateButton,
  },
  props: {
    distribution: {
      type: Object,
      required: true,
    },
  },
  apollo: {
    hostnames: {
      query: ListHostnames,
      variables() {
        return {
          id: this.distribution.id,
        };
      },
    },
  },
  methods: {
    async remove(hostname) {
      const response = await deleteDistributionHostname(
        hostname,
        this.distribution.bunny_id
      );
      if (response) {
        // I don't think we need to refresh the distribution page, just the hostnames list?
        // this.$emit('refresh');
        this.refresh();
      }
    },
    refresh() {
      this.$apollo.queries.hostnames.refetch();
    },
  },
  data() {
    return {
      searchQuery: '',
      fields: [
        {
          key: 'value',
          label: 'Domain',
        },
        {
          key: 'has_certificate',
          label: 'SSL Certificate',
        },
        // {
        //   key: 'force_ssl',
        //   label: 'Force SSL',
        //   formatter:(value) => {
        //     return !!value ? 'Enabled' : 'Disabled';
        //   },
        // },
        {
          key: 'action',
          label: '',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
      ],
    };
  },
};
</script>
<style scoped>
.distribution-hostname-item {
  display: flex;
  justify-content: space-between;
  border-top: solid 1px var(--cs-gray-01);
  padding-top: 10px;
}
</style>
