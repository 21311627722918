<template>
  <div>
    <div class="bp-action-item">
      <div class="bp-action-item__details">
        <div class="bp-action-item__left">
          <div class="bp-action-item__left--top">
            <div class="cs-textstyle-paragraph-bold bp-action-item--text-06">
              {{ action.name }}
            </div>
            <div class="vertical"></div>
            <div class="cs-textstyle-paragraph-small bp-action-item--text-06">
              ${{ formattedNumber }} per {{ action.billing_unit }}
            </div>
          </div>
          <div
            class="cs-textstyle-paragraph-small bp-action-item--text-06 mt-2"
          >
            {{ action.description }}
          </div>
        </div>
        <cs-button fill="outline" corners="rounded" @click="invoke()"
          >Run</cs-button
        >
      </div>
    </div>
    <app-job-modal
      :show="showJobModal"
      :action="action"
      @close="showJobModal = false"
    />
  </div>
</template>
<script>
import AppJobModal from '@/components/jobs/JobModal.vue';

export default {
  data() {
    return {
      showJobModal: false,
    };
  },
  components: {
    AppJobModal,
  },
  props: {
    action: {
      type: Object,
      required: true,
    },
  },
  methods: {
    invoke() {
      this.showJobModal = true;
    },
  },
  computed: {
    formattedNumber() {
      return (`${this.action.billing_price}`).replace(/(-?)(\d*)\.?(\d*)e([+-]\d+)/,
        function(a,b,c,d,e) {
          return e < 0
            ? b + '0.' + Array(1-e-c.length).join(0) + c + d
            : b + c + d + Array(e-d.length+1).join(0);
        });
    }
  }
};
</script>
<style scoped>
.bp-action-item {
  background-color: var(--cs-gray-00);
  padding-top: var(--cs-card-padding-y);
  border-radius: 5px;
}
.bp-action-item__details {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: flex-start;
  padding: var(--cs-card-padding-x) var(--cs-card-padding-y);
  padding-top: 0;
}
.bp-action-item__top {
  display: flex;
  gap: 5px;
}
.bp-action-item__left--top {
  display: flex;
  align-items: center;
  gap: 10px;
}
.bp-action-item--text-06 {
  color: var(--cs-gray-06);
}

.bp-action-item__center {
  flex: 1;
  text-align: center;
}
.bp-action-item__line {
  margin: 0 120px 0 var(--cs-card-padding-x);
  color: var(--cs-gray-01);
  opacity: 1;
}

.vertical {
  border-left: 1px solid var(--cs-gray-03);
  height: 20px;
}
</style>
