<template>
  <cs-card>
    <div slot="header" class="bp-card-header">
      <div class="cs-textstyle-item-heading">Cache Policies</div>
      <app-distribution-cache-edit 
        :distribution="distribution"
        :timeOptions="timeOptions"
        @success="$emit('on-edit-settings')" />
    </div>
    <div slot="body">
      <hr class="bp-light-line">
      <app-simple-card-table :items="items" />
    </div>
  </cs-card>
</template>
<script>
import AppDistributionCacheEdit from '@/components/distributions/DistributionCacheEdit.vue';
import AppSimpleCardTable from '@/components/distributions/SimpleCardTable.vue';
export default {
  components: {
    AppDistributionCacheEdit,
    AppSimpleCardTable,
  },
  props: {
    distribution: {
      type: Object,
      required: true,
    },
  },
  data() {
    const timeOptions = [{
          label: 'Inherit origin settings',
          value: '-1',
        },{
          label: '1 hour',
          value: '3600',
        },{
          label: '1 day',
          value: '86400',
        },{
          label: '1 week',
          value: '604800',
        },{
          label: '1 month',
          value: '2630000',
        },{
          label: '1 year',
          value: '31536000',
        },
      ];
    return {
      items: [
        {
          label: 'Server Cache Expiration Time',
          value: timeOptions.find(
                    elem => elem.value === `${this.distribution.cache_control_max_age_override}`
                  ).label,
        },
        {
          label: 'Browser Cache Expiration Time',
          value: timeOptions.find(
                    elem => elem.value === `${this.distribution.cache_control_public_max_age_override}`)
                    .label,
        },
      ],
      timeOptions: timeOptions,
    };
  },
};
</script>
