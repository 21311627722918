document.body.addEventListener('keyup', (e) => {
  if (e.key === 'Escape') {
    // Get a list of the modal backdrops (by default, open modals have backdrops)
    const openModals = document.querySelectorAll('.cs-modal .has-backdrop');

    // Hard to know which one is on top, but generally this will be the last one.
    // Usually we only have one modal open at once, so shouldn't be a problem.
    const lastOpenModal = openModals[openModals.length - 1];

    // Programmatically click on the backdrop to trigger the default "close" event the component expects
    if (lastOpenModal) lastOpenModal.click();
  }
});
