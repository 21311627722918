<template>
  <div>
    <cs-button @click="selectFile()" corners="rounded">Upload</cs-button>
    <app-upload-modal
      :show="show"
      :selectedFile="selectedFile"
      :providerId="providerId"
      :bucketName="bucketName"
      :uploadPath="uploadPath"
      :bucketId="bucketId"
      @close="onClose"
    ></app-upload-modal>
  </div>
</template>

<script>
import AppUploadModal from '@/components/upload/UploadModal.vue';
import ListUploadJobs from '@/gql/upload/ListUploadJobs.gql';

export default {
  components: { AppUploadModal },
  props: {
    bucketId: {
      type: String,
      required: true,
    },
    prefix: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      show: false,
      selectedFile: null,
      uploadPath: '',
      providerId: this.$route.params.provider_id,
      bucketName: this.$route.params.bucket_name,
      uploading: false,
      useAcceleratedUpload: true,
    };
  },
  methods: {
    selectFile() {
      const f = document.createElement('input');
      f.type = 'file';
      f.addEventListener('input', (evt) => {
        this.show = true;
        this.selectedFile = f.files[0];
        this.uploadPath = (this.prefix || '') + this.selectedFile.name
      });
      f.click();
      // this.show = true;
    },
    onClose() {
      // this.creds = {};
      this.$emit('close');
      this.show = false;
    },
  },
};
</script>
<style scoped>
.bp-upload-modal-body {
  display: flex;
  padding: 10px;
}
.bp-upload-modal-body div {
  flex: 1;
  text-align: center;
}
.bp-upload-arrow {
  font-size: 30px;
  padding: 20px;
}
</style>
