<template>
  <div>
    <app-page-header title="Job History">
    </app-page-header>
    <cs-page-tabs class="tabs-without-x-padding">
      <cs-page-tab label="Actions">
        <app-actions-history-tab />
      </cs-page-tab>
      <cs-page-tab label="Import">
        <app-import-history-tab />
      </cs-page-tab>
      <cs-page-tab label="Upload">
        <app-upload-history-tab />
      </cs-page-tab>
    </cs-page-tabs>
  </div>
</template>
<script>
import AppPageHeader from '@/components/general/PageHeader.vue';
import AppListControlBar from '@/components/general/ListControlBar.vue';
import AppUploadHistoryTab from '@/components/jobs/UploadHistoryTab.vue';
import AppActionsHistoryTab from '@/components/jobs/ActionsHistoryTab.vue';
import AppImportHistoryTab from '@/components/jobs/ImportHistoryTab.vue';

export default {
  components: {
    AppPageHeader,
    AppListControlBar,
    AppUploadHistoryTab,
    AppActionsHistoryTab,
    AppImportHistoryTab,
  },
};
</script>
