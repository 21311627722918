<template>
  <div>
    <div class="copy-success">
      <cs-alert
        variant="success"
        title="Copied!"
        can-close
        v-show="showCopiedSuccessMsg"
      ></cs-alert>
    </div>
    <cs-card class="info-card">
      <template slot="body">
        <div class="bucket-info">
          <div
            class="bucket-info-block"
            v-for="(info, index) in bucketDisplayInfo"
            :key="index"
          >
            <div class="cs-textstyle-paragraph-small-bold info-label">
              {{ info.label }}
            </div>
            <div class="cs-textstyle-paragraph info-value">
              <app-display-date-time
                v-if="info.type === 'datetime'"
                :value="info.value"
                utc
                display-format="D MMM YYYY, h:mm A (UTC)"
              />
              <template v-else>{{ info.value }}</template>
              <i
                v-if="info.icon"
                :class="info.icon"
                @click="info.iconAction"
              ></i>
            </div>
          </div>
        </div>
      </template>
    </cs-card>
  </div>
</template>
<script>
import AppDisplayDateTime from '@/components/general/DisplayDateTime.vue';

export default {
  components: {
    AppDisplayDateTime,
  },
  props: {
    bucket: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showCopiedSuccessMsg: false,
    };
  },
  computed: {
    bucketDisplayInfo() {
      if (!this.bucket) return null;
      return [
        {
          label: 'Name',
          value: this.bucket.name,
          icon: 'cs-icons-copy',
          iconAction: () => {
            navigator.clipboard.writeText(this.bucket.name).then(() => {
              this.showCopiedSuccessMsg = true;
              setTimeout(() => {
                this.showCopiedSuccessMsg = false;
              }, 2500);
            });
          },
        },
        {
          label: 'Provider',
          value: this.bucket.provider.name,
        },
        {
          label: 'Region',
          value: this.bucket.region,
        },
        {
          label: 'Created Date',
          value: this.bucket.created_at,
          type: 'datetime',
        },
      ];
    },
  },
};
</script>
<style scoped>
.region-tags {
  display: flex;
  gap: 5px;
  margin-top: 5px;
}
.bucket-info {
  display: flex;
  justify-content: space-between;
}
.info-label {
  color: var(--cs-gray-06);
}
.info-value {
  color: var(--cs-gray-05);
}
.info-value i {
  color: var(--cs-primary-base);
}
.info-card {
  --cs-card-background: var(--cs-gray-01) !important;
}
.bp-bucket-details {
  margin-top: 16px;
  margin-bottom: 16px;
}
.copy-success {
  position: absolute;
  margin-top: -80px;
}
.copy-success .cs-alert {
  width: 140px;
}
</style>
