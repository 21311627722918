<template>
  <div>
    <cs-modal :show="show" class="bp-bucket-modal" @close="onClose">
      <div slot="header">Connect Bucket</div>
      <div slot="body">
        <cs-form class="bp-bucket-modal__vertical-form">
          <!-- Cloud Provider -->
          <cs-select
            required
            v-model="creds.provider_id"
            :options="providers"
            label="Storage Provider"
          />
          <!-- Region -->
          <cs-select
            required
            v-model="creds.region"
            :options="selectedProvider.regions"
            label="Region"
            v-if="selectedProvider && selectedProvider.regions.length"
            @input="handleRegionChange"
          />
          <!-- Endpoint -->
          <cs-input
            required
            label="Endpoint"
            v-model="creds.endpoint"
            v-show="selectedProvider && !selectedProvider.regions.length"
          />
          <!-- Bucket name -->
          <cs-input required label="Bucket Name" v-model="creds.name" />
          <!-- Access Key -->
          <cs-input
            required
            label="Access Key"
            v-model="creds.access_key"
          />
          <!-- Secret Key -->
          <cs-input
            required
            type="password"
            label="Secret Key"
            v-model="creds.secret_key"
          />
        </cs-form>
        <cs-alert
          v-if="errorTitle"
          :title="errorTitle"
          :content="errorDescription"
          variant="danger"
        />
      </div>
      <cs-button
        slot="main"
        variant="primary"
        @click="onConnect"
        fill="solid"
        :disabled="!validForm || connecting"
        corners="rounded"
        >Connect</cs-button
      >
      <div v-if="connecting" class="bp-bucket-modal__loading" slot="body">
        <div class="bp-bucket-modal__loading-background"></div>
        <cs-spinner />
      </div>
    </cs-modal>
  </div>
</template>

<script>
import ListProviders from '@/gql/providers/ListProviders.gql';
import ConnectBucket from '@/gql/buckets/ConnectBucket.gql';

export default {
  components: {},
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    createMode: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    selectedProvider() {
      if (this.providers && this.creds.provider_id) {
        return this.providers.find((item) => (item.value == this.creds.provider_id));
      }
      return null;
    },
    selectedRegion() {
      if (this.creds.region) {
        return this.selectedProvider.regions.find((item) => (item.value == this.creds.region));
      }
      return null;
    },
    handleRegionChange() {
      if (this.selectedRegion) {
        this.creds.endpoint = this.selectedRegion.endpoint;
      }
    },
    validForm() {
      const formProps = [
        'name',
        'provider_id',
        'region',
        'access_key',
        'secret_key',
        'endpoint',
      ];
      const values = formProps.filter((prop) => this.creds[prop]);
      return values.length === formProps.length;
    },
  },
  data() {
    return {
      creds: {},
      connecting: false,
      errorTitle: null,
      errorDescription: null,
    };
  },
  apollo: {
    providers: ListProviders,
  },
  methods: {
    async onConnect() {
      try {
        this.connecting = true;
        this.creds.type = this.type;
        this.creds.create = this.createMode;
        console.log(this.creds);
        const { bucket } = await this.$apollo.mutate({
          mutation: ConnectBucket,
          variables: this.creds,
        });
        this.errorTitle = null;
        this.errorDescription = null;
        this.$emit('success', bucket);
        this.connecting = false; // Must be set before onCall
        this.onClose();
      } catch (err) {
        this.errorTitle = err.message;
        this.errorDescription = err.graphQLErrors[0].extensions.exception.description;
        this.connecting = false;
      }
    },
    onClose() {
      if (this.connecting) return;
      this.creds = {};
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.bp-bucket-modal.cs-modal {
  /* ---cs-modal-width: 550px !important; */
  ---cs-modal-max-height: 90%;
}
.bp-bucket-modal.cs-modal >>> .cs-modal__popup {
  border: none;
}
.bp-bucket-modal__vertical-form {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
}
.bp-bucket-modal__vertical-form >>> .cs-textstyle-label-text {
  font-weight: bold;
}
.bp-bucket-modal__vertical-form >>> .cs-select__label {
  display: block;
}

.bp-bucket-modal__loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bp-bucket-modal__loading,
.bp-bucket-modal__loading-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.bp-bucket-modal__loading-background {
  background-color: var(--cs-gray-06);
  opacity: 0.2;
}
</style>
