// IMPORTANT: try to ensure feature parity (except for slot) with @/components/general/Dialog.vue

import { promiseTimeout } from '@/services/utils';

const _configureOptions = (options) => {
  if (typeof options === 'string') return { message: options };
  return { ...options };
};

const _configureButton = (button) => {
  button.classList.add('cs-textstyle-button-text');
};

const _addElement = (className, parent, elType) => {
  elType = elType || 'div';
  const el = document.createElement(elType);
  el.className = className;
  if (parent && parent.appendChild) parent.appendChild(el);
  return el;
};

const _openDialog = async (dialog) => {
  // Make visible, then animate in
  dialog.classList.add('dialog--open');
  await promiseTimeout(1);
  dialog.classList.add('dialog--animate');
};

const _close = async (dialog) => {
  if (!dialog) return;
  // Animate out, then hide
  dialog.classList.remove('dialog--animate');
  await promiseTimeout(300);
  dialog.classList.remove('dialog--open');
  dialog.remove();
};

const _open = async (options) => {
  return new Promise((resolve) => {
    const dialog = _addElement('dialog');

    // Handle Android back button
    dialog.addEventListener('backbutton', () => {
      let resolveData = false;
      if (['alert'].indexOf(options.type) > -1) resolveData = true;
      resolve(resolveData);
      _close(dialog);
    });

    _addElement('dialog__background', dialog);

    const content = _addElement('dialog__content', dialog);

    const scrollableContent = _addElement('dialog__scrollable', content);

    if (options.image) {
      const image = _addElement('dialog__image', scrollableContent, 'img');
      image.src = options.image;
    }

    if (options.title) {
      const title = _addElement('dialog__title', scrollableContent);
      title.innerText = options.title;
      title.classList.add('cs-textstyle-item-heading');
    }

    if (options.message) {
      const message = _addElement('dialog__message', scrollableContent);
      message.innerText = options.message;
      message.classList.add('cs-textstyle-paragraph');
    }

    if (['prompt'].indexOf(options.type) > -1) {
      const input = _addElement('dialog__input', scrollableContent, 'input');
      input.placeholder = options.placeholder || '';
    }

    const buttons = _addElement('dialog__buttons', content);

    // Okay button
    const okButton = _addElement('dialog__button', buttons, 'button');
    okButton.innerText = options.okayLabel || 'OK';
    if (['delete'].indexOf(options.type) > -1) {
      okButton.innerText = options.deleteLabel || 'Delete';
      okButton.classList.add('dialog__button--delete');
    }

    okButton.addEventListener('click', () => {
      let resolveData = true;
      if (options.type === 'prompt') {
        // Show the value of the input when Okay button is clicked
        const resolveInput = content.querySelector('input');
        if (resolveInput) resolveData = resolveInput.value;
      }
      resolve(resolveData);
      _close(dialog);
    });
    _configureButton(okButton);

    // Cancel button
    if (['confirm', 'prompt', 'delete'].indexOf(options.type) > -1) {
      const cancelButton = _addElement(
        'dialog__button dialog__button--cancel',
        buttons,
        'cs-button'
      );
      cancelButton.innerText = options.cancelLabel || 'Cancel';
      cancelButton.addEventListener('click', () => {
        resolve(false);
        _close(dialog);
      });
      _configureButton(cancelButton);
    }

    document.body.appendChild(dialog);
    _openDialog(dialog);
  });
};

const typeAlert = (options) => {
  const alertOptions = _configureOptions(options);
  alertOptions.type = 'alert';
  return _open(alertOptions);
};

const typeConfirm = (options) => {
  const alertOptions = _configureOptions(options);
  alertOptions.type = 'confirm';
  return _open(alertOptions);
};

const typePrompt = (options) => {
  const alertOptions = _configureOptions(options);
  alertOptions.type = 'prompt';
  return _open(alertOptions);
};

const typeDelete = (options) => {
  const alertOptions = _configureOptions(options);
  alertOptions.type = 'delete';
  return _open(alertOptions);
};

const typeCustom = (options) => {
  const alertOptions = _configureOptions(options);
  return _open(alertOptions);
};

export default {
  alert: typeAlert,
  confirm: typeConfirm,
  prompt: typePrompt,
  show: typeCustom,
  delete: typeDelete,
};
