<template>
  <div>
    <cs-button @click="showModal = true" corners="rounded"
      >Add Domain</cs-button
    >
    <cs-modal
      :show="showModal"
      class="bp-modal"
      @close="onClose"
      layout="large"
    >
      <div slot="header">Add Custom Domain</div>
      <div slot="body">
        <cs-form class="bp-modal__vertical-form">
          <p class="cs-textstyle-paragraph-bold">
            Please CNAME &quot;{{ distribution.cname_domain }}&quot; to &quot;{{
              hostname
            }}&quot;
          </p>
          <cs-input
            required
            label="Domain"
            v-model="hostname"
            placeholder="cdn.mydomain.com"
          />
        </cs-form>
      </div>

      <cs-button
        slot="main"
        variant="primary"
        @click="save"
        fill="solid"
        :disabled="saving"
        corners="rounded"
        >Add</cs-button
      >
      <div v-if="saving" class="bp-modal__loading" slot="body">
        <div class="bp-modal__loading-background"></div>
        <cs-spinner />
      </div>
    </cs-modal>
  </div>
</template>

<script>
import AddHostname from '@/gql/distributions/AddHostname.gql';

export default {
  components: {},
  props: {
    distribution: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      saving: false,
      showModal: false,
      hostname: null,
    };
  },
  methods: {
    async save() {
      try {
        this.saving = true;
        const resp = await this.$apollo.mutate({
          mutation: AddHostname,
          variables: {
            id: this.distribution.bunny_id,
            hostname: this.hostname,
          },
        });
        this.saving = false;
        this.$emit('added');
        this.onClose();
      } catch (err) {
        this.$dialog.alert(err.message);
      }
      this.saving = false;
    },
    onClose() {
      this.showModal = false;
    },
  },
};
</script>

<style scoped>
.bp-modal.cs-modal {
  /* ---cs-modal-width: 550px !important; */
  ---cs-modal-max-height: 90%;
}
.bp-modal.cs-modal >>> .cs-modal__popup {
  border: none;
}
.bp-modal__vertical-form {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
}
.bp-modal__vertical-form >>> .cs-textstyle-label-text {
  font-weight: bold;
}
.bp-modal__vertical-form >>> .cs-select__label {
  display: block;
}

.bp-modal__loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bp-modal__loading,
.bp-modal__loading-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.bp-modal__loading-background {
  background-color: var(--cs-gray-06);
  opacity: 0.2;
}
</style>
