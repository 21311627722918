<template>
  <cs-card class="large-content-card objects-list">
    <div slot="body">
      <app-list-control-bar
        @refresh="refresh()"
        :placeholder="`Filter ${category} jobs`"
        v-model="searchQuery"
      >
      </app-list-control-bar>
      <div v-if="actionJobs">
        <b-table
          :items="actionJobs"
          :fields="fields"
          :filter="searchQuery"
          :per-page="perPage"
          :current-page="currentPage"
          outlined
          hover
          selectable
          table-variant="light"
          tbody-tr-class="eca-dashboard__table-row small-padding"
          @row-selected="onJobSelected"
          :busy="$apollo.queries.jobs.loading"
          show-empty
          @filtered="onFiltered"
        >
          <div slot="table-busy" class="bp-table-empty">
            <cs-spinner />
          </div>
          <div slot="empty" class="bp-table-empty">No jobs.</div>
          <div slot="emptyfiltered" class="bp-table-empty">
            No matching jobs.
          </div>
          <template v-slot:cell(created_at)="data">
            <app-display-date-time :value="data.item.created_at" />
          </template>
          <template v-slot:cell(status)="data">
            <cs-tag :class="data.item.status">{{ data.item.status }}</cs-tag>
          </template>
        </b-table>
        <cs-pagination
          v-if="totalPages"
          :count="totalPages"
          :value="currentPage"
          layout="medium"
          @input="currentPage = $event"
        >
        </cs-pagination>
      </div>
    </div>
  </cs-card>
</template>
<script>
import AppListControlBar from '@/components/general/ListControlBar.vue';
import AppDisplayDateTime from '@/components/general/DisplayDateTime.vue';

import ListJobs from '@/gql/jobs/ListJobs.gql';

export default {
  components: {
    AppListControlBar,
    AppDisplayDateTime,
  },
  apollo: {
    jobs: {
      query: ListJobs,
    },
  },
  props: {
    category: {
      type: String,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
  },
  computed: {
    actionJobs() {
      return this.jobs
        ? this.category && this.category === 'action'
          ? this.jobs.filter((j) => j.action.input_type !== 'other')
          : this.category && this.category === 'import'
          ? this.jobs.filter((j) => j.action.input_type === 'other')
          : []
        : [];
    },
  },
  methods: {
    refresh() {
      return this.$apollo.queries.jobs.refetch();
    },
    onJobSelected(rows) {
      const job = rows[0];
      if (job) {
        this.$router.push({
          name: 'JobDetail',
          params: job,
        });
      }
    },
    calculateTotalPages(items) {
      this.totalPages =
        items && items.length ? Math.ceil(items.length / this.perPage) : 0;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
      this.calculateTotalPages(filteredItems);
    },
  },
  data() {
    return {
      searchQuery: '',
      currentPage: 1,
      perPage: 50,
      totalPages: 1,
    };
  },
  watch: {
    'actionJobs.length'(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.calculateTotalPages(this.actionJobs);
      }
    },
  },
  beforeMount() {
    this.refresh();
  },
};
</script>
