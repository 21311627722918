<template>
  <div>
    <cs-modal :show="show" class="bp-bucket-modal" layout="medium" @close="onClose">
      <div slot="header">Disconnect Bucket</div>
      <div slot="body" class="modal-body">
        <cs-alert v-if="error" variant="danger" title="Error!" :content="error">
        </cs-alert>
        <p class="cs-textstyle-paragraph-bold">
          Are you sure you want to disconnect {{bucket.name}}?
        </p>
        <p class="cs-textstyle-paragraph-small">
          This will not delete your bucket or underlying files. To confirm, type <strong>{{bucket.name}}</strong> below.
        </p>
        <cs-form class="bp-bucket-modal__vertical-form">
          <cs-input v-model="bucketName" required label="Bucket Name" :placeholder="'Type ' + bucket.name + ' here'" />
        </cs-form>
      </div>
      <cs-button
        slot="main"
        :disabled="!validForm || connecting"
        variant="danger"
        corners="rounded"
        @click="onUpdate"
        >Disconnect</cs-button
      >
      <div v-if="connecting" class="bp-bucket-modal__loading" slot="body">
        <div class="bp-bucket-modal__loading-background"></div>
        <cs-spinner />
      </div>
    </cs-modal>
  </div>
</template>

<script>
import DisconnectBucket from '@/gql/buckets/DisconnectBucket.gql';

export default {
  components: {},
  props: {
    bucket: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      connecting: false,
      bucketName: '',
      error: '',
    };
  },
  computed: {
    validForm() {
      return !!this.bucketName && !!this.bucketName.length && this.bucketName === this.bucket.name;
    },
  },
  methods: {
    async onUpdate() {
      try {
        this.connecting = true;
        await this.$apollo.mutate({
          mutation: DisconnectBucket,
          variables: {
            id: this.bucket.id,
          },
        });
        this.resetForm();
        this.$emit('success');
      } catch (err) {
        this.error = err.message;
      }
      this.connecting = false;
    },
    onClose() {
      if (this.connecting) return;
      this.resetForm();
      this.$emit('close');
    },
    resetForm() {
      this.bucketName = '';
    },
  },
};
</script>

<style scoped>
.bp-bucket-modal.cs-modal {
  ---cs-modal-max-height: 90%;
}
.bp-bucket-modal.cs-modal >>> .cs-modal__popup {
  border: none;
}
.bp-bucket-modal__vertical-form {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
}
.bp-bucket-modal__vertical-form >>> .cs-textstyle-label-text {
  font-weight: bold;
}
.bp-bucket-modal__vertical-form >>> .cs-select__label {
  display: block;
}

.bp-bucket-modal__loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bp-bucket-modal__loading,
.bp-bucket-modal__loading-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.bp-bucket-modal__loading-background {
  background-color: var(--cs-gray-06);
  opacity: 0.2;
}
.cs-alert {
  margin-top: 10px;
}
.modal-body {
  padding-top: 16px;
}
</style>
