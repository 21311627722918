<template>
  <div>
    <cs-button
      fill="outline"
      variant="danger"
      corners="rounded"
      @click="disconnect()"
    >
      Disconnect
    </cs-button>
    <app-disconnect-bucket-modal
      :show="showDisconnectBucketModal"
      :bucket="bucket"
      @close="onDisconnectBucketModalClose"
      @success="onDisconnectBucketModalSuccess"
    />
  </div>
</template>
<script>
import AppDisconnectBucketModal from '@/components/buckets/DisconnectBucketModal.vue';

export default {
  components: {
    AppDisconnectBucketModal
  },
  data() {
    return {
      showDisconnectBucketModal: false,
    }
  },
  props: { 
    bucket: {
      type: Object,
      required: true
    },
  },
  methods: {
    async disconnect() {
      this.showDisconnectBucketModal = true;
    },
    onDisconnectBucketModalClose() {
      this.showDisconnectBucketModal = false;
    },
    onDisconnectBucketModalSuccess() {
      this.showDisconnectBucketModal = false;
      this.$emit('bucket-disconnected');
    },
  }
};
</script>
