<template>
  <!-- Don't reuse this component -->
  <app-destructive-item-modal
    :header="header"
    :title="title"
    :message="message"
    :input-label="inputLabel"
    :keyword="keyword"
    :delete-label="deleteLabel"
    :button-variant="buttonVariant"
    :mutation="mutation"
    :show="showModal"
    @delete="onDelete"
    @close="showModal = false"
  />
</template>
<script>
import $bus from '@/services/bus';

import AppDestructiveItemModal from '@/components/destructive/DestructiveItemModal.vue';

export default {
  components: {
    AppDestructiveItemModal,
  },
  data() {
    return {
      header: '',
      title: '',
      message: '',
      inputLabel: '',
      keyword: '',
      deleteLabel: '',
      buttonVariant: '',
      mutation: null,

      showModal: false,
      responseId: '',
    };
  },
  created() {
    $bus.$on('destructive-action-modal', (data) => {
      this.responseId = data.responseId;

      const params = data.params;
      this.header = params.header || '';
      this.title = params.title || '';
      this.message = params.message || '';
      this.inputLabel = params.inputLabel || '';
      this.keyword = params.keyword || '';
      this.deleteLabel = params.deleteLabel || 'Delete';
      this.buttonVariant = params.buttonVariant || 'Delete';
      this.mutation = params.mutation || null;

      this.showModal = true;
    });
  },
  methods: {
    onDelete($event) {
      this.showModal = false;
      $bus.$emit(this.responseId, $event);
    },
  },
};
</script>
