var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bp-filecard-contents"},[_c('div',{staticClass:"bp-filecard-main"},[_c('router-link',{attrs:{"to":{
        name: 'FileDetail',
        params: {
          provider_id: _vm.bucket.provider.id,
          bucket_name: _vm.bucket.name,
          path_key: _vm.path_key,
        },
      }}},[_c('div',{staticClass:"cs-textstyle-paragraph"},[_c('span',[_vm._v(_vm._s(_vm.bucket.name))]),_c('span',[_vm._v("/")]),_c('span',[_vm._v(_vm._s(_vm.path_key))])])])],1),_vm._v("  "),(_vm.showAvailable)?_c('i',{staticClass:"cs-icons-download",on:{"click":function($event){return _vm.openFile()}}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }