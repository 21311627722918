<template>
  <cs-card>
    <div slot="header" class="bp-card-header">
      <div class="cs-textstyle-item-heading">Image Optimizer</div>
      <app-distribution-optimizer-edit
        :distribution="distributionSettings"
        @success="$emit('on-edit-settings')"
      />
    </div>
    <div slot="body">
      <hr class="bp-light-line" />
      <app-simple-card-table v-if="distributionSettings" :items="items" />
    </div>
  </cs-card>
</template>
<script>
import AppDistributionOptimizerEdit from '@/components/distributions/DistributionOptimizerEdit.vue';
import AppSimpleCardTable from '@/components/distributions/SimpleCardTable.vue';
export default {
  components: {
    AppDistributionOptimizerEdit,
    AppSimpleCardTable,
  },
  props: {
    distribution: {
      type: Object,
      required: true,
    },
  },
  computed: {
    distributionSettings() {
      return this.distribution;
    },
    items() {
      return [
        {
          label: 'Image Optimization',
          value: this.distribution.optimizer_enabled ? 'Enabled' : 'Disabled',
        },
        {
          label: 'Max Desktop Image Width',
          value: `${this.distribution.optimizer_desktop_max_width}px`,
          subItem: true,
          disabled: !this.distribution.optimizer_enabled,
        },
        {
          label: 'Desktop Image Quality',
          value: `${this.distribution.optimizer_image_quality}%`,
          subItem: true,
          disabled: !this.distribution.optimizer_enabled,
        },
        {
          label: 'Max Mobile Image Width',
          value: `${this.distribution.optimizer_mobile_max_width}px`,
          subItem: true,
          disabled: !this.distribution.optimizer_enabled,
        },
        {
          label: 'Mobile Image Quality',
          value: `${this.distribution.optimizer_mobile_image_quality}%`,
          subItem: true,
          disabled: !this.distribution.optimizer_enabled,
        },
        {
          label: 'Compress Images to WebP',
          value: this.distribution.optimizer_enable_webp
            ? 'Enabled'
            : 'Disabled',
          subItem: true,
          disabled: !this.distribution.optimizer_enabled,
        },
      ];
    },
  },
};
</script>
