<template>
  <div class="bp-page-header">
    <div>
      <h1 class="cs-textstyle-page-title">{{ title }}</h1>
      <slot name="subtitle"></slot>
    </div>
    <div class="bp-page-header-buttons">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: false,
    },
  },
};
</script>
<style>
/* Page Header */
.bp-page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.bp-page-header-buttons {
  display: flex;
  gap: 10px;
}
</style>
