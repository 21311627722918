<template>
  <div class="breadcrumbs">
    <router-link
      v-for="item of items"
      :key="item.label"
      :to="{
        name: item.view,
        params: item.params,
        query: item.query,
      }"
      class="breadcrumb"
      >{{ item.label }}</router-link
    >
  </div>
</template>
<script>
export default {
  props: {
    bucket: {
      type: Object,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    tabIndex: {
      type: [String, Number],
      required: false,
    },
  },
  data() {
    return {
      provider_id: this.bucket
        ? this.bucket.provider_id
        : this.$route.params.provider_id,
      bucket_name: this.bucket
        ? this.bucket.name
        : this.$route.params.bucket_name,
    };
  },
  computed: {
    items() {
      let crumbs = [
        {
          label: 'Buckets',
          view: 'BucketList',
          params: {},
          query: {},
        },
        {
          label: this.provider_id.toUpperCase(),
          view: 'BucketList',
          params: {},
          query: {},
        },
      ];
      if (this.$route.params.path_key || this.bucket) {
        crumbs.push({
          label: this.bucket_name,
          view: 'BucketDetail',
          params: {
            provider_id: this.provider_id,
            bucket_name: this.bucket_name,
          },
          query: {},
        });
      }

      if (this.$route.params.path_key) {
        // Render path
        const pathKeyParts = this.$route.params.path_key.split('/');
        pathKeyParts.splice(pathKeyParts.length - 2, 2);
        if (pathKeyParts.length > 0) {
          let currentParts = [];
          for (let part of pathKeyParts) {
            currentParts.push(part);
            crumbs.push({
              label: part,
              view: 'FolderDetail',
              params: {
                provider_id: this.$route.params.provider_id,
                bucket_name: this.$route.params.bucket_name,
                path_key: currentParts.join('/') + '/',
              },
              query: {},
            });
          }
        }
      }

      if (this.label && !this.tabIndex) {
        crumbs.push({
          label: this.label,
          query: {},
        });
      }

      if (this.label && this.tabIndex) {
        crumbs.push({
          label: this.label,
          view: 'BucketDetail',
          params: {
            provider_id: this.provider_id,
            bucket_name: this.bucket_name,
          },
          query: { tab: this.tabIndex },
        });
      }

      return crumbs;
    },
  },
};
</script>
<style scoped>
.breadcrumbs {
  display: flex;
}
.breadcrumb {
  color: var(--cs-gray-04);
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 40px;
  --bs-breadcrumb-margin-bottom: 0;
}
.breadcrumb:hover {
  color: var(--cs-primary-base);
}
.breadcrumb:not(:last-child):after {
  color: var(--cs-gray-04);
  content: '>';
  margin-left: 5px;
  margin-right: 5px;
}
</style>
