<template>
  <div class="bp-user-list">
    <div class="bp-user-list__header">
      <div>
        <div class="cs-textstyle-page-title">Users</div>
        <p class="cs-textstyle-paragraph">Coming soon</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  apollo: {},
};
</script>

<style scoped></style>
