import jwt from 'jsonwebtoken';
import { publicKey, signOptions } from './utils';
import GetUser from '@/gql/user/GetUser.gql';
import $apollo from '@/services/apollo';

const isLoggedIn = () => {
  if (!localStorage.access_token) return false;
  if (localStorage.access_token) {
    const token = localStorage.access_token;
    jwt.verify(token, publicKey, signOptions, (err) => {
      if (err) {
        localStorage.clear();
        return false;
      }
      return true;
    });
  }
  return true;
};

const getUserId = () => {
  if (localStorage.access_token) {
    const token = localStorage.access_token;
    const { user } = jwt.decode(token, publicKey, signOptions);
    return user.id;
  }
  return null;
};

const getUser = async () => {
  const {
    data: { user },
  } = await $apollo.query({
    query: GetUser,
    variables: {
      getUserId: getUserId(),
    },
  });
  return user;
};

export default {
  isLoggedIn,
  getUserId,
  getUser,
};
