<template>
  <div>
    <cs-button fill="outline" @click="openModal" corners="rounded" size="small"
      >Edit</cs-button
    >
    <cs-modal
      :show="showModal"
      class="bp-modal"
      @close="onClose"
      layout="large"
    >
      <div slot="header">Edit Cache Policy</div>
      <div slot="body">
        <cs-form class="bp-modal__vertical-form">
          <cs-select
            required
            label="Server Cache Expiration Time"
            :options="timeOptions"
            v-model="cache_control_max_age_override"
          />
          <cs-select
            required
            label="Browser Cache Expiration Time"
            :options="timeOptions"
            v-model="cache_control_public_max_age_override"
          />
        </cs-form>
      </div>
      <cs-button
        slot="main"
        variant="primary"
        @click="save"
        fill="solid"
        :disabled="saving || !validForm"
        corners="rounded"
        >Save</cs-button
      >
      <div v-if="saving" class="bp-modal__loading" slot="body">
        <div class="bp-modal__loading-background"></div>
        <cs-spinner />
      </div>
    </cs-modal>
  </div>
</template>

<script>
import UpdateDistributionEdit from '@/gql/distributions/UpdateDistributionCache.gql';

export default {
  components: {},
  props: {
    distribution: {
      type: Object,
      required: true,
    },
    timeOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      saving: false,
      showModal: false,
      errorTitle: null,
      errorDescription: null,
      cache_control_max_age_override: '',
      cache_control_public_max_age_override: '',
      options: {
        id: this.distribution.bunny_id,
        cache_control_max_age_override:
          this.distribution.cache_control_max_age_override,
        cache_control_public_max_age_override:
          this.distribution.cache_control_public_max_age_override,
      },
    };
  },
  computed: {
    validForm() {
      return (
        this.cache_control_max_age_override &&
        this.cache_control_public_max_age_override
      );
    },
  },
  methods: {
    async save() {
      try {
        this.saving = true;
        this.options.cache_control_max_age_override =
          +this.cache_control_max_age_override;
        this.options.cache_control_public_max_age_override =
          +this.cache_control_public_max_age_override;
        const resp = await this.$apollo.mutate({
          mutation: UpdateDistributionEdit,
          variables: this.options,
        });
        this.saving = false;
        this.$emit('success')
      } catch (err) {
        this.errorTitle = err.message;
        this.errorDescription =
          err.graphQLErrors[0].extensions.exception.description;
      }
      this.saving = false;
    },
    onClose() {
      this.showModal = false;
      this.errorTitle = null;
      this.errorDescription = null;
      this.$emit('close');
    },
    openModal() {
      this.cache_control_max_age_override = `${this.options.cache_control_max_age_override}`;
      this.cache_control_public_max_age_override = `${this.options.cache_control_max_age_override}`;
      this.showModal = true;
    },
  },
};
</script>

<style scoped>
.bp-modal.cs-modal {
  /* ---cs-modal-width: 550px !important; */
  ---cs-modal-max-height: 90%;
}
.bp-modal.cs-modal >>> .cs-modal__popup {
  border: none;
}
.bp-modal__vertical-form {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
}
.bp-modal__vertical-form >>> .cs-textstyle-label-text {
  font-weight: bold;
}
.bp-modal__vertical-form >>> .cs-select__label {
  display: block;
}

.bp-modal__loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bp-modal__loading,
.bp-modal__loading-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.bp-modal__loading-background {
  background-color: var(--cs-gray-06);
  opacity: 0.2;
}
</style>
