<template>
  <div>
    <cs-button
      fill="outline"
      variant="primary"
      corners="rounded"
      @click="editConnection()"
    >
      Edit Keys
    </cs-button>
    <app-edit-bucket-modal
      :show="showEditBucketModal"
      :bucket="bucket"
      @close="onEditBucketModalClose"
      @success="onEditBucketModalSuccess"
    />
  </div>
</template>
<script>
import AppEditBucketModal from '@/components/buckets/EditBucketModal.vue';

export default {
  components: {
    AppEditBucketModal,
  },
  data() {
    return {
      showEditBucketModal: false,
    };
  },
  props: {
    bucket: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async editConnection() {
      this.showEditBucketModal = true;
    },
    onEditBucketModalClose() {
      this.showEditBucketModal = false;
    },
    onEditBucketModalSuccess() {
      this.showEditBucketModal = false;
      this.$emit('bucket-edit-done');
    },
  },
};
</script>
