<template>
  <cs-form class="bp-job-modal__vertical-form">
    <cs-input
      v-for="v of variables"
      :key="v.id"
      :required="v.required"
      :label="v.label"
      v-model="parameters[v.name]"
    />
  </cs-form>
</template>

<script>
export default {
  props: {
    variables: {
      type: Array, 
      required: true,
    },
    initialValues: {
      type: Object, 
      required: false, 
    }
  }, 
  data() {
    return {
      parameters: {},
    }
  },
  computed: {
    validForm() {
      const formProps = this.variables.map(v => v.name);
      const values = formProps.filter((prop) => this.parameters[prop]);
      return values.length === formProps.length;
    },
  },
  methods: {
    getActionParams() {
      return this.parameters;
    }
  },
  beforeMount() {
    this.parameters = this.initialValues; 
  },
  watch: {
    validForm() {
      this.$emit('input', this.validForm);
    },
  }
}
</script>