export const getLastNoOfElements = (array, noOfElements) =>
  array.slice(1).slice(-noOfElements);

export const randomColor = () => {
  const code = Math.random().toString(16).slice(-6);
  return `#${code}`;
};

export const publicKey = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCaQCAIs+0y/9d+KXrc/367Md1S
UXZ4PGlP4jBT2eiXPsn4UmBWJHE+QXnPiwmcwqPzZsrdVqSU+QwJ5etE6RdDAm3w
eGesZKcbh6GaK2OCSYbyL/FTVCLWtCQbxUIZLL4FnkspaWJBX4sBb923UVKRX3rY
ecDoIiWCfG1WGXq2lwIDAQAB
-----END PUBLIC KEY-----`;

export const signOptions = {
  algorithm: 'RS256',
};

// Allows for await timeout
export const promiseTimeout = (duration) => {
  duration = duration || 0;
  return new Promise((resolve) => {
    setTimeout(resolve, duration);
  });
};

export const convertSecToMinSec = (milliseconds) => {
  const seconds = milliseconds / 1000 % 60;
  const minutes = Math.floor(milliseconds / 1000 / 60);
  return `${minutes}m ${parseFloat(seconds).toFixed(2)}s`;
};
