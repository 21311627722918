<template>
  <cs-card>
    <div slot="header" class="bp-card-header">
      <div class="cs-textstyle-item-heading">General</div>
      <app-distribution-basic-edit :distribution="distribution" @success="onSuccess"/>
    </div>
    <div slot="body">
      <hr class="bp-light-line">
      <app-simple-card-table :items="items" />
    </div>
  </cs-card>
</template>
<script>
import AppDistributionBasicEdit from '@/components/distributions/DistributionBasicEdit.vue';
import AppSimpleCardTable from '@/components/distributions/SimpleCardTable.vue';

export default {
  components: {
    AppDistributionBasicEdit,
    AppSimpleCardTable,
  },
  props: {
    distribution: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      items: [
        {
          label: 'Path',
          value: this.distribution.path || '(root)',
        },
        {
          label: 'Status',
          value: this.distribution.enabled ? 'Enabled' : 'Disabled',
        },
        {
          label: 'Monthly Bandwidth Limit',
          labelNote:
            this.distribution.monthly_bandwidth_limit === 0
              ? 'Unlimited bandwidth'
              : 'Set to 0 for unlimited',
          labelNoteIcon: 'cs-icons-check-success',
          value: this.distribution.monthly_bandwidth_limit,
        },
        {
          label: 'DDOS Shield',
          value: this.distribution.shield_ddos_protection_enabled
            ? 'Enabled'
            : 'Disabled',
        },
      ],
    };
  },
  methods: {
    onSuccess(){
      this.$emit('on-edit-settings')
    }
  }
};
</script>