// Files/Objects
import DeleteFile from '@/gql/files/DeleteFile.gql';

// Distributions
import DeleteDistribution from '@/gql/distributions/DeleteDistribution.gql';
import PurgeDistribution from '@/gql/distributions/PurgeDistribution.gql';
import RemoveHostname from '@/gql/distributions/RemoveHostname.gql';

// Api keys
import DeleteProjectKey from '@/gql/projects/DeleteProjectKey.gql';

import $bus from '@/services/bus';
import apolloClient from '@/services/apollo';
import $dialog from '@/services/dialog';

const _sendAndAwait = (params) => {
  return new Promise((resolve) => {
    const responseId = Math.floor(Math.random() * 1000000000).toString();
    $bus.$once(responseId, (response) => {
      resolve(response);
    });
    $bus.$emit('destructive-action-modal', {
      responseId,
      params,
    });
  });
};

export const deleteFile = async (providerId, bucketName, pathKey) => {
  const confirmDelete = await $dialog.delete(
    'Are you sure you wish to delete this object?'
  );
  return new Promise((resolve) => {
    if (confirmDelete) {
      apolloClient
        .mutate({
          mutation: DeleteFile,
          variables: {
            provider_id: providerId,
            bucket_name: bucketName,
            path_key: pathKey,
          },
        })
        .then(resolve)
        .catch((error) => {
          $dialog.alert(`Error: ${error.message}`);
        });
    }
  });
};

export const deleteDistribution = (distribution) => {
  const params = {
    header: 'Delete Distribution',
    title: `Are you sure you want to delete the distribution "${distribution.id}"?`,
    message: "This change can't be reversed",
    inputLabel: 'Distribution Name',
    keyword: distribution.id,
    mutation: {
      mutation: DeleteDistribution,
      variables: { id: distribution.id },
    },
  };
  return _sendAndAwait(params);
};

export const purgeDistribution = (distribution) => {
  const params = {
    header: 'Purge Distribution',
    message:
      'Purging a URL will remove the file from the edge cache and re-download it from your origin server',
    inputLabel: 'Distribution Name',
    keyword: distribution.id,
    deleteLabel: 'Purge',
    buttonVariant: 'primary',
    mutation: {
      mutation: PurgeDistribution,
      variables: { id: distribution.id },
    },
  };
  return _sendAndAwait(params);
};

export const deleteDistributionHostname = (hostname, bunnyId) => {
  const params = {
    header: 'Delete Domain',
    title: `Are you sure you want to delete the domain "${hostname.value}"?`,
    message: "This change can't be reversed",
    inputLabel: 'Domain Name',
    keyword: hostname.value,
    mutation: {
      mutation: RemoveHostname,
      variables: {
        id: bunnyId,
        hostname: hostname.value,
      },
    },
  };
  return _sendAndAwait(params);
};

export const deleteProjectKey = async (projectKey) => {
  const params = {
    header: 'Delete Project Key',
    title: `Are you sure you want to delete the project key "${projectKey.name}"?`,
    message: "This change can't be reversed",
    inputLabel: 'Key Name',
    keyword: projectKey.name,
    mutation: {
      mutation: DeleteProjectKey,
      variables: { id: projectKey.id },
    },
  };
  return _sendAndAwait(params);
};
