<template>
  <div>
    <app-bucket-breadcrumbs />
    <cs-alert
      v-if="isAccelerated && !file"
      variant="warning"
      title="Accelerated Upload In Progress"
      content="Your data is being transferred from the edge to your bucket. Please wait a few moments."
    >
    </cs-alert>
    <cs-spinner v-if="$apollo.queries.file.loading" />
    <div v-if="file">
      <app-page-header :title="file.key.split('/').pop()">
        <cs-button
          @click="openFile()"
          variant="primary"
          fill="outline"
          corners="rounded"
          icon="cs-icons-download"
          >Open</cs-button
        >
        <cs-button
          @click="deleteFile()"
          variant="danger"
          fill="outline"
          corners="rounded"
          icon="cs-icons-delete"
          >Delete</cs-button
        >
      </app-page-header>
      <div class="bp-page-tabs">
        <cs-page-tabs class="tabs-without-x-padding">
          <cs-page-tab label="Details">
            <app-file-detail-details-tab :file="file" :preview-src="src" />
          </cs-page-tab>
          <cs-page-tab label="Actions">
            <app-action-list :actions="file.actions" />
          </cs-page-tab>
        </cs-page-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import GetFile from '@/gql/files/GetFile.gql';
import GetFileSignedUrl from '@/gql/files/GetFileSignedUrl.gql';
import AppBucketBreadcrumbs from '@/components/buckets/BucketBreadcrumbs.vue';
import AppPageHeader from '@/components/general/PageHeader.vue';
import AppFileDetailDetailsTab from '@/components/files/FileDetailDetailsTab.vue';
import AppActionList from '@/components/actions/ActionList.vue';

import { deleteFile } from '@/services/confirm-delete';

export default {
  components: {
    AppBucketBreadcrumbs,
    AppPageHeader,
    AppFileDetailDetailsTab,
    AppActionList,
  },
  props: {
    provider_id: {
      type: String,
      required: true,
    },
    bucket_name: {
      type: String,
      required: true,
    },
    path_key: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      src: '',
      isAccelerated: this.$route.query.accelerated,
    };
  },
  apollo: {
    file: {
      query: GetFile,
      variables() {
        return {
          provider_id: this.provider_id,
          bucket_name: this.bucket_name,
          key: this.path_key,
        };
      },
      fetchPolicy: 'network-only',
      error(error) {
        setTimeout(async () => {
          console.log('Retry');
          try {
            if (!this.$apollo.queries.file.loading) {
              await this.$apollo.queries.file.refetch();
            }
          } catch (err) {}
        }, 1000);
        return true;
      },
    },
  },
  methods: {
    async deleteFile() {
      const response = await deleteFile(
        this.$route.params.provider_id,
        this.$route.params.bucket_name,
        this.file.key,
        this
      );
      if (response)
        this.$router.push({
          name: 'BucketDetail',
          params: {
            provider_id: this.provider_id,
            bucket_name: this.bucket_name,
          },
        });
    },
    openFile() {
      window.open(this.src);
    },
  },
  async mounted() {
    const resp = await this.$apollo.query({
      query: GetFileSignedUrl,
      variables: {
        provider_id: this.$route.params.provider_id,
        bucket_name: this.$route.params.bucket_name,
        path_key: this.$route.params.path_key,
      },
    });
    this.src = resp.data.url;
  },
};
</script>
<style scoped></style>
