<template>
  <div>
    <cs-modal :show="show" class="bp-bucket-modal" @close="onClose">
      <div slot="header">Create Distribution</div>
      <div slot="body">
        <cs-form class="bp-bucket-modal__vertical-form">
          <div class="create-distribution-id-input-area">
            <cs-input
              required
              label="Default Domain"
              v-model="distribution.id"
              placeholder="e.g. my-great-distribution"
              default-label="Only alphanumeric characters and hyphens. Must be globally unique."
              class="create-distribution-id-input"
            />
            <div>.efficientcdn.com</div>
          </div>
          <cs-input
            label="Source Path"
            v-model="distribution.path"
            default-label="Leave blank to use the root of your bucket"
          />
        </cs-form>
        <cs-alert
          v-if="errorTitle"
          :title="errorTitle"
          :content="errorDescription"
          variant="danger"
        />
      </div>
      <cs-button
        slot="main"
        variant="primary"
        @click="onConnect"
        fill="solid"
        :disabled="!validForm || creating"
        corners="rounded"
        >Create</cs-button
      >
      <div v-if="creating" class="bp-bucket-modal__loading" slot="body">
        <div class="bp-bucket-modal__loading-background"></div>
        <cs-spinner />
      </div>
    </cs-modal>
  </div>
</template>

<script>
import CreateDistribution from '@/gql/distributions/CreateDistribution.gql';

export default {
  components: {},
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    provider_id: {
      type: String,
      required: true,
    },
    bucket_name: {
      type: String,
      required: true,
    },
  },
  computed: {
    validForm() {
      const regexp = /^[a-zA-Z0-9-_]+$/; // alphanumeric, hyphens, and underscores
      const idMeetsRequirements =
        this.distribution.id && this.distribution.id.search(regexp) !== -1;
      const formProps = ['id'];
      const values = formProps.filter((prop) => this.distribution[prop]);
      return values.length === formProps.length && idMeetsRequirements;
    },
  },
  data() {
    return {
      distribution: {
        provider_id: this.provider_id,
        bucket_name: this.bucket_name,
        path_key: '',
        id: null,
      },
      creating: false,
      errorTitle: null,
      errorDescription: null,
    };
  },
  methods: {
    async onConnect() {
      try {
        this.creating = true;
        this.distribution.id = this.distribution.id.toLowerCase();
        const { bucket } = await this.$apollo.mutate({
          mutation: CreateDistribution,
          variables: this.distribution,
        });
        this.$router.push({
          name: 'DistributionDetail',
          params: { id: this.distribution.id },
          query: {
            tab: this.$route.query.tab,
          },
        });
        this.errorTitle = null;
        this.errorDescription = null;
        this.$emit('success', bucket);
      } catch (err) {
        this.errorTitle = err.message;
        this.errorDescription =
          err.graphQLErrors[0].extensions.exception.description;
      }
      this.creating = false;
    },
    onClose() {
      if (this.creating) return;
      this.distribution = {};
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.bp-bucket-modal.cs-modal {
  /* ---cs-modal-width: 550px !important; */
  ---cs-modal-max-height: 90%;
}
.bp-bucket-modal.cs-modal >>> .cs-modal__popup {
  border: none;
}
.bp-bucket-modal__vertical-form {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.bp-bucket-modal__vertical-form >>> .cs-textstyle-label-text {
  font-weight: bold;
}
.bp-bucket-modal__vertical-form >>> .cs-select__label {
  display: block;
}

.bp-bucket-modal__loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bp-bucket-modal__loading,
.bp-bucket-modal__loading-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.bp-bucket-modal__loading-background {
  background-color: var(--cs-gray-06);
  opacity: 0.2;
}
.create-distribution-id-input-area {
  display: flex;
  align-items: center;
  gap: 10px;
}
.create-distribution-id-input {
  flex: 1;
}
</style>
