<template>
  <app-gray-card>
    <app-list-control-bar placeholder="Filter actions" v-model="searchQuery" />
    <div class="bp-action-list__wrapper">
      <app-action-item
        :action="action"
        v-for="action of filteredActions"
        :key="action.id"
      />
    </div>
  </app-gray-card>
</template>
<script>
import AppGrayCard from '@/components/general/GrayCard.vue';
import AppListControlBar from '@/components/general/ListControlBar.vue';
import AppActionItem from '@/components/actions/ActionItem.vue';

export default {
  components: {
    AppGrayCard,
    AppListControlBar,
    AppActionItem,
  },
  props: {
    actions: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
  },
  computed: {
    filteredActions() {
      if (!this.actions) return [];
      const actions = this.actions;
      const query = this.searchQuery.trim().toLowerCase();
      if (!query) return actions;

      const filteredActions = actions.filter((action) => {
        return action.name.toLowerCase().indexOf(query) > -1;
      });
      return filteredActions;
    },
  },
  data() {
    return {
      searchQuery: '',
    };
  },
};
</script>
<style scoped>
.bp-action-list__wrapper {
  border-radius: 5px;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
}
</style>
