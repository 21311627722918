<template>
  <div class="breadcrumbs">
    <router-link
      v-for="item of items"
      :key="item.label"
      :to="{
        name: item.view,
      }"
      class="breadcrumb"
      >{{ item.label }}</router-link
    >
  </div>
</template>
<script>
export default {
  props: {
    label: {
      type: String,
      required: false,
    },
  },
  computed: {
    items() {
      const crumbs = [
        {
          label: 'CDN',
          view: 'DistributionsList',
        },
      ];
      if (this.label) {
        crumbs.push({
          label: this.label,
        });
      }

      return crumbs;
    },
  },
};
</script>
<style scoped>
.breadcrumbs {
  display: flex;
}
.breadcrumb {
  color: var(--cs-gray-04);
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 40px;
  --bs-breadcrumb-margin-bottom: 0;
}
.breadcrumb:hover {
  color: var(--cs-primary-base);
}
.breadcrumb:not(:last-child):after {
  color: var(--cs-gray-04);
  content: '>';
  margin-left: 5px;
  margin-right: 5px;
}
</style>
