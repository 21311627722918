<template>
  <app-gray-card>
    <div class="bp-file-detail__file-details">
      <div class="bp-file-detail__file-details-item">
        <div class="cs-textstyle-paragraph-small-bold">File Type</div>
        <div class="cs-textstyle-paragraph-small">
          {{ fileName }}
        </div>
      </div>
      <div class="bp-file-detail__file-details-item">
        <div class="cs-textstyle-paragraph-small-bold">File Size</div>
        <div class="cs-textstyle-paragraph-small">
          {{ file.size | size }}
        </div>
      </div>
      <div class="bp-file-detail__file-details-item">
        <div class="cs-textstyle-paragraph-small-bold">Created Date</div>
        <div class="cs-textstyle-paragraph-small">
          <app-display-date-time
            :value="file.created_at"
            utc
            display-format="D MMM YYYY, h:mm A (UTC)"
          />
        </div>
      </div>
    </div>
    <hr />
    <app-file-embed
      class="bp-file-detail__file-embed"
      :file="file"
      :src="src"
      v-if="src"
    />
  </app-gray-card>
</template>
<script>
import AppFileEmbed from '@/components/files/FileEmbed.vue';
import AppGrayCard from '@/components/general/GrayCard.vue';
import AppDisplayDateTime from '@/components/general/DisplayDateTime.vue';

export default {
  components: {
    AppFileEmbed,
    AppGrayCard,
    AppDisplayDateTime,
  },
  props: {
    file: {
      type: Object,
      required: true,
    },
    previewSrc: {
      type: String,
      default: '',
    },
  },
  computed: {
    fileName() {
      if (!this.file) return '';
      const parts = this.file.key.split('.');
      return parts[parts.length - 1];
    },
    src() {
      return this.previewSrc;
    },
  },
};
</script>
<style scoped>
.bp-file-detail__file-details {
  display: flex;
  flex-wrap: wrap;
  gap: 16px 80px;
}
</style>
