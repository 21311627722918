<template>
  <div class="eca-accounts-list">
    <div class="eca-dashboard__header">
      <div>
        <div class="cs-textstyle-page-title">Buckets</div>
      </div>
      <div class="eca-accounts-list__accounts">
        <cs-button corners="rounded" @click="openConnectBucketModal(true)">
          Create Bucket
        </cs-button>
        <cs-button
          corners="rounded"
          @click="openConnectBucketModal(false)"
          fill="outline"
        >
          Connect Bucket
        </cs-button>
      </div>
    </div>
    <app-gray-card class="buckets-list">
      <app-list-control-bar
        @refresh="refresh"
        v-model="val"
        placeholder="Filter buckets"
      />
      <div>
        <b-table
          :items="buckets"
          :fields="fields"
          outlined
          hover
          selectable
          :filter="filteredResult"
          table-variant="light"
          tbody-tr-class="eca-dashboard__table-row"
          @row-clicked="onRowClicked"
          :busy="$apollo.queries.buckets.loading"
        >
          <template #table-busy>
            <div class="eca-dashboard__table-busy">
              <cs-spinner class="eca-spinner" />
            </div>
          </template>
          <!-- Provider -->
          <template v-slot:cell(provider)="data">
            <div>{{ data.item.provider.name }}</div>
          </template>
          <!-- Time -->
          <template v-slot:cell(created_at)="data">
            <app-display-date-time
              :value="data.item.created_at"
              display-format="D MMM YYYY"
            />
          </template>
          <template v-slot:cell(action)="data">
            <i
              :id="`${data.item.id}`"
              class="cs-icons-options"
              tabindex="-1"
            ></i>
            <b-popover
              :ref="`${data.item.id}-menu`"
              :target="`${data.item.id}`"
              placement="leftbottom"
              triggers="blur hover"
              custom-class="bg-dark"
            >
              <div>
                <div class="bp-service-list__menu-body">
                  <div
                    class="bp-service-list__menu-item"
                    @click.stop="editBucket(data.item)"
                  >
                    Edit Keys
                  </div>
                  <div
                    class="bp-service-list__menu-item"
                    @click.stop="removeBucket(data.item)"
                  >
                    Disconnect Bucket
                  </div>
                </div>
              </div>
            </b-popover>
          </template>
        </b-table>
      </div>
    </app-gray-card>
    <!-- Empty State -->
    <div v-if="buckets && !buckets.length">
      <app-empty-state
        :show-button="false"
        description="You have not connected any buckets yet"
      />
    </div>
    <app-connect-bucket-modal
      :show="showConnectBucketModal"
      :createMode="createMode"
      @close="closeConnectBucketModal"
      @success="refresh"
    />
    <app-edit-bucket-modal
      :show="showEditBucketModal"
      :bucket="bucket"
      @close="onEditBucketModalClose"
      @success="onEditBucketModalSuccess"
    />
    <app-disconnect-bucket-modal
      :show="showDisconnectBucketModal"
      :bucket="bucket"
      @close="onDisconnectBucketModalClose"
      @success="onDisconnectBucketModalSuccess"
    />
  </div>
</template>

<script>
import ListBuckets from '@/gql/buckets/ListBuckets.gql';

import AppEmptyState from '@/components/general/EmptyState.vue';
import AppConnectBucketModal from '@/components/buckets/ConnectBucketModal.vue';
import AppEditBucketModal from '@/components/buckets/EditBucketModal.vue';
import AppDisconnectBucketModal from '@/components/buckets/DisconnectBucketModal.vue';
import AppListControlBar from '@/components/general/ListControlBar.vue';
import AppGrayCard from '@/components/general/GrayCard.vue';
import AppDisplayDateTime from '@/components/general/DisplayDateTime.vue';

export default {
  components: {
    AppEmptyState,
    AppConnectBucketModal,
    AppEditBucketModal,
    AppListControlBar,
    AppDisconnectBucketModal,
    AppGrayCard,
    AppDisplayDateTime,
  },
  apollo: {
    buckets: {
      query: ListBuckets,
    },
  },
  data() {
    return {
      val: '',
      createMode: false,
      fields: [
        {
          key: 'provider',
          label: 'Cloud Provider',
          thClass: 'eca-dashboard__table-head',
        },
        {
          key: 'region',
          label: 'Region',
          thClass: 'eca-dashboard__table-head',
        },
        {
          key: 'name',
          label: 'Bucket Name',
          thClass: 'eca-dashboard__table-head',
        },
        {
          key: 'created_at',
          label: 'Created',
          thClass: 'eca-dashboard__table-head',
        },
        {
          key: 'action',
          label: '',
          thClass: 'eca-dashboard__table-head text-right',
          tdClass: 'text-right',
        },
      ],
      bucket: {},
      showConnectBucketModal: false,
      showEditBucketModal: false,
      showDisconnectBucketModal: false,
    };
  },
  computed: {
    filteredResult() {
      if (this.val) {
        return this.val;
      }
      return '';
    },
  },
  beforeMount() {
    if (this.$apollo.queries.buckets) {
      this.refresh();
    }
  },
  methods: {
    openConnectBucketModal(createMode) {
      this.showConnectBucketModal = true;
      this.createMode = createMode;
    },
    closeConnectBucketModal(refresh) {
      this.showConnectBucketModal = false;
      if (refresh) {
        this.refresh();
      }
    },
    closeActionPopover() {
      this.$root.$emit('bv::hide::popover');
    },
    async removeBucket(bucket) {
      this.closeActionPopover();
      this.bucket = bucket;
      this.showDisconnectBucketModal = true;
    },
    onRowClicked(item) {
      this.$router.push({
        name: 'BucketDetail',
        params: {
          provider_id: item.provider.id,
          bucket_name: item.name,
        },
      });
    },
    onModalClose() {
      this.showBucketModal = false;
    },
    onModalSuccess(bucket) {
      this.showBucketModal = false;
      this.refresh();
    },
    editBucket(bucket) {
      this.bucket = bucket;
      this.showEditBucketModal = true;
      this.closeActionPopover();
    },
    onEditBucketModalClose() {
      this.showEditBucketModal = false;
    },
    onEditBucketModalSuccess() {
      this.showEditBucketModal = false;
      this.refresh();
    },
    onDisconnectBucketModalClose() {
      this.showDisconnectBucketModal = false;
    },
    onDisconnectBucketModalSuccess() {
      this.showDisconnectBucketModal = false;
      this.refresh();
    },
    async refresh() {
      await this.$apollo.queries.buckets.refetch();
    },
  },
};
</script>

<style scoped>
.eca-accounts-list p.cs-textstyle-paragraph {
  color: var(--cs-gray-05);
}
.videoinsight-history__feature {
  margin: 5px;
}
.eca-account-list__features {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
  vertical-align: middle;
}
.eca-spinner__wrapper {
  position: absolute;
  height: 100%;
  width: 100%;
}
.eca-spinner__wrapper .eca-spinner {
  position: relative;
  top: 40%;
  left: 40%;
  --cs-spinner-color: var(--cs-gray-03) !important;
}
.eca-buckets-list__buckets {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.eca-accounts-list__accounts {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.buckets-list.cs-card {
  margin-top: 24px !important;
}
.buckets-list table {
  margin-bottom: 0;
}
.eca-account-list__search-bar.cs-search-bar {
  border: 1px solid var(--cs-gray-02);
}
</style>
