import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/views/login/Login.vue';
import Dashboard from '@/views/Dashboard.vue';
import BucketList from '@/views/buckets/BucketList.vue';
import BucketDetail from '@/views/buckets/BucketDetail.vue';
import UserList from '@/views/users/UserList.vue';
import Billing from '@/views/billing/Billing.vue';
import ProjectKeyList from '@/views/projects/ProjectKeyList.vue';
import FolderDetail from '@/views/folders/FolderDetail.vue';
import FileDetail from '@/views/files/FileDetail.vue';
import JobHistory from '@/views/jobs/JobHistory.vue';
import JobDetail from '@/views/jobs/JobDetail.vue';
import TriggerDetail from '@/views/triggers/TriggerDetail.vue';
import DistributionsList from '@/views/distributions/DistributionsList.vue';
import DistributionDetail from '@/views/distributions/DistributionDetail.vue';

import $auth from '@/services/auth';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    redirect: '/buckets',
  },
  {
    path: '/buckets',
    name: 'BucketList',
    component: BucketList,
  },
  {
    path: '/buckets/:provider_id/:bucket_name',
    name: 'BucketDetail',
    component: BucketDetail,
    props: true,
  },
  {
    path: '/buckets/:provider_id/:bucket_name/objects/:path_key',
    name: 'FileDetail',
    component: FileDetail,
    props: true,
  },
  {
    path: '/buckets/:provider_id/:bucket_name/folders/:path_key',
    name: 'FolderDetail',
    component: FolderDetail,
    props: true,
  },
  {
    path: '/jobs',
    name: 'JobHistory',
    component: JobHistory,
  },
  {
    path: '/jobs/:id',
    name: 'JobDetail',
    component: JobDetail,
    props: true,
  },
  {
    path: '/distributions',
    name: 'DistributionsList',
    component: DistributionsList,
  },
  {
    path: '/distributions/:id',
    name: 'DistributionDetail',
    component: DistributionDetail,
    props: true,
  },
  {
    path: '/triggers/:id',
    name: 'TriggerDetail',
    component: TriggerDetail,
    props: true,
  },
  {
    path: '/users',
    name: 'UserList',
    component: UserList,
  },
  {
    path: '/keys',
    name: 'ProjectKeyList',
    component: ProjectKeyList,
  },
  {
    path: '/billing',
    name: 'Billing',
    component: Billing,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach(async (to, from, next) => {
  if ($auth.isLoggedIn() && to.fullPath.includes('/login')) {
    next('/');
  } else if (!$auth.isLoggedIn() && !to.fullPath.includes('/login')) {
    next('/login');
  } else {
    next();
  }
});

export default router;
