<template>
  <cs-button @click="onDelete" variant="danger" fill="outline" corners="rounded"
    >Delete</cs-button
  >
</template>
<script>
import { deleteDistribution } from '@/services/confirm-delete';

export default {
  props: {
    distribution: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async onDelete() {
      const response = await deleteDistribution(this.distribution);
      if (response) this.$router.back();
    },
  },
};
</script>
