<template>
  <app-gray-card>
    <app-list-control-bar
      @refresh="refresh"
      v-model="searchQuery"
      placeholder="Filter objects"
    >
      <app-upload-button
        :bucketId="bucketId"
        prefix=""
        @upload="refresh()"
        @backgroundStarted="showUploadWarning = true"
        @backgroundComplete="
          showUploadWarning = false;
          refresh();
        "
      />
    </app-list-control-bar>
    <cs-alert
      variant="info"
      title="One or more files are being transferred to your bucket"
      can-close
      v-show="showUploadWarning"
    >
    </cs-alert>
    <b-table
      id="objects-table"
      primary-key="key"
      :items="files"
      :fields="fields"
      :filter="searchQuery"
      :per-page="perPage"
      :current-page="currentPage"
      outlined
      hover
      selectable
      table-variant="light"
      :busy="$apollo.queries.files.loading"
      show-empty
      thead-tr-class="eca-dashboard__table-head-row"
      tbody-tr-class="eca-dashboard__table-row"
      @row-clicked="onFileSelected"
      @filtered="onFiltered"
    >
      <div slot="table-busy" class="bp-table-empty">
        <cs-spinner />
      </div>
      <div slot="empty" class="bp-table-empty">This folder is empty.</div>
      <div slot="emptyfiltered" class="bp-table-empty">
        No matching objects.
      </div>
      <template v-slot:cell(key)="data">
        <img
          :src="getIcon(data.item)"
          alt=""
          class="file-icon filter-black-to-blue"
        />
        <span class="bp-item-name" v-if="data.item.is_folder">{{
          getKey(data.item.key)
        }}</span>
        <span class="bp-item-name" v-if="!data.item.is_folder">{{
          data.item.key
        }}</span>
      </template>
      <template v-slot:cell(created_at)="data">
        <app-display-date-time
          :value="data.item.created_at"
          display-format="D MMM YYYY"
        />
      </template>
      <template v-slot:cell(action)="data">
        <i
          v-if="!data.item.is_folder"
          :id="`${data.item.key}`"
          class="cs-icons-options"
        ></i>
        <b-popover
          :ref="`${data.item.key}-menu`"
          :target="`${data.item.key}`"
          placement="bottom"
          triggers="blur hover"
          custom-class="bg-dark"
        >
          <div>
            <div class="bp-service-list__menu-body">
              <div
                class="bp-service-list__menu-item"
                @click.stop="downloadFile(data.item)"
              >
                Open
              </div>
              <div
                class="bp-service-list__menu-item"
                @click.stop="deleteFile(data.item)"
              >
                Delete
              </div>
            </div>
          </div>
        </b-popover>
      </template>
    </b-table>
    <cs-pagination
      v-if="totalPages"
      :count="totalPages"
      :value="currentPage"
      layout="medium"
      @input="currentPage = $event"
    >
    </cs-pagination>
  </app-gray-card>
</template>

<script>
import ListFiles from '@/gql/files/ListFiles.gql';
import AppListControlBar from '@/components/general/ListControlBar.vue';
import { getIconForKey, getFileSignedUrl } from '@/services/fileicon';
import { deleteFile } from '@/services/confirm-delete';
import AppGrayCard from '@/components/general/GrayCard.vue';
import AppUploadButton from '@/components/upload/UploadButton.vue';
import AppDisplayDateTime from '@/components/general/DisplayDateTime.vue';

export default {
  components: {
    AppListControlBar,
    AppGrayCard,
    AppUploadButton,
    AppDisplayDateTime,
  },
  props: {
    bucketId: {
      type: String,
      required: true,
    },
    prefix: {
      type: String,
      required: false,
    },
    bucketId: {
      type: String,
      required: true,
    },
  },
  apollo: {
    files: {
      query: ListFiles,
      variables() {
        return {
          provider_id: this.provider_id,
          bucket_name: this.bucket_name,
          prefix: this.prefix,
        };
      },
      result() {
        if (!this.searchQuery) {
          this.calculateTotalPages(this.files);
        }
      },
    },
  },
  data() {
    return {
      showUploadWarning: false,
      searchQuery: '',
      currentPage: 1,
      perPage: 50,
      totalPages: 1,
      provider_id: this.$route.params.provider_id,
      bucket_name: this.$route.params.bucket_name,
      fields: [
        {
          key: 'key',
          label: 'Key',
        },
        {
          key: 'size',
          label: 'Size',
          formatter: (value) => {
            return this.$options.filters.size(value);
          },
        },
        {
          key: 'extension',
          label: 'Type',
          tdClass: 'short-cell',
        },
        {
          key: 'created_at',
          label: 'Created',
        },
        {
          key: 'action',
          label: '',
          tdClass: 'text-right',
        },
      ],
    };
  },
  methods: {
    getKey(str) {
      return str.substring(0, str.lastIndexOf('/'));
    },
    refreshList() {
      this.$apollo.queries.files.refetch();
    },
    onFileSelected(file) {
      if (file) {
        if (file.is_folder) {
          this.$router.push({
            name: 'FolderDetail',
            params: {
              provider_id: this.provider_id,
              bucket_name: this.bucket_name,
              path_key: file.key,
            },
          });
        } else {
          this.$router.push({
            name: 'FileDetail',
            params: {
              provider_id: this.provider_id,
              bucket_name: this.bucket_name,
              path_key: file.key,
            },
          });
        }
      }
    },
    getIcon(file) {
      return getIconForKey(file.key);
    },
    async refresh() {
      await this.$apollo.queries.files.refetch();
    },
    async deleteFile(object) {
      const response = await deleteFile(
        this.provider_id,
        this.bucket_name,
        object.key
      );
      if (response) this.refresh();
    },
    async downloadFile(item) {
      const url = await getFileSignedUrl(
        this.provider_id,
        this.bucket_name,
        item.key
      );
      window.open(url);
    },
    calculateTotalPages(items) {
      this.totalPages =
        items && items.length ? Math.ceil(items.length / this.perPage) : 0;
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
      this.calculateTotalPages(filteredItems);
    },
  },
};
</script>

<style scoped></style>
