<template>
  <div class="breadcrumbs">
    <router-link
      v-for="item of items"
      :key="item.label"
      :to="{
        name: item.view,
        params: item.params,
      }"
      class="breadcrumb"
      >{{ item.label }}</router-link
    >
  </div>
</template>
<script>
export default {
  props: {
    job: {
      type: Object,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      job_id: this.job ? this.job.id : this.$route.params.id,
    };
  },
  computed: {
    items() {
      const crumbs = [
        {
          label: 'Job History',
          view: 'JobHistory',
          params: {},
        },
      ];
      if (this.job) {
        crumbs.push({
          label: this.job.action.name,
          view: 'JobDetail',
          params: {
            id: this.job.id,
          },
        });
      }
      if (this.label) {
        crumbs.push({
          label: this.label,
        });
      }

      return crumbs;
    },
  },
};
</script>
<style scoped>
.breadcrumbs {
  display: flex;
}
.breadcrumb {
  color: var(--cs-gray-04);
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 40px;
  --bs-breadcrumb-margin-bottom: 0;
}
.breadcrumb:hover {
  color: var(--cs-primary-base);
}
.breadcrumb:not(:last-child):after {
  color: var(--cs-gray-04);
  content: '>';
  margin-left: 5px;
  margin-right: 5px;
}
</style>
