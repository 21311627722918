<template>
  <app-job-history-action-layout category="import" :fields="fields" />
</template>
<script>
import AppJobHistoryActionLayout from '@/components/jobs/JobHistoryActionLayout.vue';

export default {
  components: {
    AppJobHistoryActionLayout,
  },
  data() {
    const thClass = 'eca-dashboard__table-head small-padding';
    return {
      fields: [
        {
          key: 'destinationBucket.name',
          label: 'Output Bucket',
          thClass: thClass,
        },
        {
          key: 'destination_path',
          label: 'Output Path',
          thClass: thClass,
        },
        {
          key: 'action',
          label: 'Action',
          thClass: thClass,
          formatter: (value) => {
            return value.name;
          },
        },
        {
          key: 'created_at',
          label: 'Date',
          thClass: thClass,
        },
        {
          key: 'billing_total',
          label: 'Cost',
          thClass: thClass,
          formatter: (value) => {
            return `$${value.toFixed(3)}`;
          },
        },
        {
          key: 'status',
          label: 'Status',
          thClass: thClass,
        },
      ],
    };
  },
};
</script>
