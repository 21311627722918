<template>
  <div>
    <cs-button
      v-if="!hostname.has_certificate"
      size="small"
      fill="outline"
      corners="rounded"
      :disabled="provisioning"
      @click="provision()"
    >
      {{displayLabel}}
    </cs-button>
    <span v-if="hostname.has_certificate">Provisioned</span>
  </div>
</template>
<script>
import ProvisionCertificate from '@/gql/distributions/ProvisionCertificate.gql';

export default {
  props: {
    hostname: {
      type: Object,
      required: true,
    },
    distribution_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      provisioning: false,
    };
  },
  computed: {
    displayLabel() {
      return this.provisioning ? 'Provisioning...' : 'Provision';
    },
  },
  methods: {
    async provision() {
      this.provisioning = true;
      try {
        const resp = await this.$apollo.mutate({
          mutation: ProvisionCertificate,
          variables: {
            id: this.distribution_id,
            hostname: this.hostname.value,
          },
        });
        console.log(resp.data);
        this.$emit('provisioned');
      } catch (err) {
        this.$dialog.alert(err.message);
      }
      this.provisioning = false;
    },
  },
};
</script>
