<template>
  <div>
    <cs-spinner v-if="!job" />
    <app-job-breadcrumbs :job="job" />

    <div v-if="job" class="bp-job-container">
      <app-page-header :title="job.id"> </app-page-header>

      <div class="copy-success">
        <cs-alert
          v-show="showCopiedSuccessMsg"
          variant="success"
          title="Copied!"
          can-close
        ></cs-alert>
      </div>
      <cs-card class="info-card">
        <div slot="body" class="bp-job-details">
          <app-job-info 
            :data="detailsInfo" 
            title="Details"
          ></app-job-info>
          <app-job-info
            :data="parametersInfo"
            :customData="customParameters"
            title="Parameters"
          ></app-job-info>
        </div>
      </cs-card>
      <div class="cs-textstyle-item-heading mt-4 mb-3">Build Logs</div>
      <app-job-logs ref="joblogs" class="bp-logs-container" :job-id="job.id" />
    </div>
  </div>
</template>
<script>
import AppJobBreadcrumbs from '@/components/jobs/JobBreadcrumbs.vue';
import AppPageHeader from '@/components/general/PageHeader.vue';
import AppJobInfo from '@/components/jobs/JobInfo.vue';
import AppJobLogs from '@/components/jobs/JobLogs.vue';
import { getLastNoOfElements, convertSecToMinSec } from '@/services/utils';

import GetJob from '@/gql/jobs/GetJob.gql';

export default {
  components: {
    AppJobBreadcrumbs,
    AppPageHeader,
    AppJobInfo,
    AppJobLogs,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  apollo: {
    job: {
      query: GetJob,
      variables() {
        return {
          id: this.id,
        };
      },
    },
  },
  data() {
    return {
      showCopiedSuccessMsg: false,
    };
  },
  computed: {
    detailsInfo() {
      if (!this.job) return;
      const items = [
        {
          label: 'Job Id',
          value: this.job.id ? getLastNoOfElements(this.job.id, 12) : '',
          icon: 'cs-icons-copy',
          iconAction: () => {
            navigator.clipboard.writeText(this.job.id).then(() => {
              this.showCopiedSuccessMsg = true;
              setTimeout(() => {
                this.showCopiedSuccessMsg = false;
              }, 2500);
            });
          },
        },
        {
          label: 'Action Name',
          value: this.job.action.name,
        },
        {
          label: 'Execution Time',
          value: this.getExecutionTime(this.job),
          hide: !this.job.started_at
        },
        {
          label: 'Creation Time',
          value: this.job.created_at,
          type: 'datetime',
        },
         {
          label: 'Cost',
          value: `$${this.job.billing_total.toFixed(3)}`,
        },
        {
          label: 'Job Status',
          value: this.job.status,
          isStatus: true,
        },
      ];
      return items.filter((v) => v.value);
    },
    parametersInfo() {
      if (!this.job) return;
      const items = [
        {
          label: 'Source Bucket Path',
          value: this.job.sourceBucket && this.job.source_path,
          bucket: this.job.sourceBucket,
          path_key: this.job.source_path,
          available: true,
          hide: !this.job.sourceBucket || !this.job.source_path,
          isFile: true,
        },
        {
          label: 'Destination Bucket Path',
          value: this.job.destinationBucket && this.job.destination_path,
          bucket: this.job.destinationBucket,
          path_key: this.job.destination_path,
          available: this.job.status == 'COMPLETED',
          isFile: true,
        },
      ];
      return items.filter((v) => v.value);
    },
    customParameters() {
      if (!this.job || !this.job.parameters) return;
      const parameterKeys = [...Object.keys(this.job.parameters)]
      const items = parameterKeys.map(parameter => ({
        label: this.job.action.variables.find(v => v.name === parameter).label,
        value: this.job.parameters[parameter],
      }))
      return items.filter((v) => v.value);
    }
  },
  mounted() {
    setInterval(() => {
      if (
        this.job &&
        this.job.status != 'COMPLETED' &&
        this.job.status != 'FAILED' &&
        !this.$apollo.queries.job.loading
      ) {
        this.$apollo.queries.job.refetch();
        if (this.$refs.joblogs) this.$refs.joblogs.refresh();
      }
    }, 5000);
  },
  methods: {
    getExecutionTime(job) {
      const startDate = new Date(job.started_at).getTime();
      const endDate = job.completed_at
        ? new Date(job.completed_at).getTime()
        : Date.now();
      return convertSecToMinSec(endDate - startDate);
    },
  },
};
</script>
<style scoped>
.bp-job-tags {
  gap: 10px;
  display: flex;
}
.bp-job-detail-infobar {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  gap: 10px;
}
.progress-card,
.pricing-card,
.file-card {
  flex: 1;
}

.info-card {
  --cs-card-background: var(--cs-gray-01) !important;
}
.copy-success {
  position: absolute;
  margin-top: -80px;
}
.copy-success .cs-alert {
  width: 140px;
}
.bp-job-container {
  display: flex;
  flex-direction: column;
}
.bp-job-container .bp-logs-container {
  flex: 1;
  overflow: auto;
  margin-bottom: 40px;
}
.bp-job-details {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
</style>
