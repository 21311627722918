<template>
  <div class="bp-distribution-detail">
    <cs-spinner v-if="$apollo.queries.distribution.loading" />
    <div v-if="!$apollo.queries.distribution.loading">
      <app-bucket-breadcrumbs
        v-if="$route.query.tab"
        :bucket="distribution.bucket"
        label="CDN"
        :tab-index="$route.query.tab"
      />
      <app-distribution-breadcrumbs v-else :label="distribution.id" />
      <app-page-header :title="distribution.id">
        <app-purge-distribution-button :distribution="distribution" />
        <app-delete-distribution-button :distribution="distribution" />
      </app-page-header>
      <cs-page-tabs>
        <cs-page-tab label="Settings">
          <app-distribution-settings-tab
            :distribution="distribution"
            @on-edit-settings="refresh"
          />
        </cs-page-tab>
        <cs-page-tab label="Custom Domains">
          <app-distribution-hostnames-tab
            :distribution="distribution"
            @refresh="refresh()"
          />
        </cs-page-tab>
        <cs-page-tab label="Usage Charts">
          Charts coming soon!
          <!-- <app-distribution-charts-tab
              :distribution="distribution"
              @refresh="refresh()"
            /> -->
        </cs-page-tab>
      </cs-page-tabs>
      <div class="row">
        <!-- <div>{{ JSON.stringify(distribution, null, 2) }}</div> -->
      </div>
    </div>
  </div>
</template>
<script>
import GetDistribution from '@/gql/distributions/GetDistribution.gql';
import AppDeleteDistributionButton from '@/components/distributions/DeleteDistributionButton.vue';
import AppPurgeDistributionButton from '@/components/distributions/PurgeDistributionButton.vue';
import AppBucketBreadcrumbs from '@/components/buckets/BucketBreadcrumbs.vue';
import AppPageHeader from '@/components/general/PageHeader.vue';
import AppDistributionSettingsTab from '@/components/distributions/DistributionSettingsTab.vue';
import AppDistributionHostnamesTab from '@/components/distributions/DistributionHostnamesTab.vue';
import AppDistributionBreadcrumbs from '@/components/distributions/DistributionBreadcrumbs.vue';

export default {
  components: {
    AppDeleteDistributionButton,
    AppPurgeDistributionButton,
    AppPageHeader,
    AppBucketBreadcrumbs,
    AppDistributionSettingsTab,
    AppDistributionHostnamesTab,
    AppDistributionBreadcrumbs,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  apollo: {
    distribution: {
      query: GetDistribution,
      variables() {
        return {
          id: this.id,
        };
      },
    },
  },
  methods: {
    async refresh() {
      await this.$apollo.queries.distribution.refetch();
    },
  },
};
</script>
<style scoped>
.bp-distribution-detail .cs-page-tabs {
  --cs-page-tabs-content-padding: 24px 0;
}
</style>
