<template>
  <div>
    <cs-modal
      :show="show"
      class="bp-bucket-modal"
      layout="medium"
      @close="onClose"
    >
      <div slot="header">Edit Keys: {{ bucket.name }}</div>
      <div slot="body">
        <cs-alert v-if="error" variant="danger" title="Error!" :content="error">
        </cs-alert>
        <cs-form class="bp-bucket-modal__vertical-form">
          <cs-input v-model="accessKey" required label="Access Key" />
          <cs-input
            v-model="secretKey"
            required
            type="password"
            label="Secret Key"
          />
        </cs-form>
      </div>
      <cs-button
        corners="rounded"
        slot="main"
        fill="solid"
        :disabled="!validForm || connecting"
        variant="primary"
        @click="onCheckCredentials"
        >Save</cs-button
      >
      <div v-if="connecting" class="bp-bucket-modal__loading" slot="body">
        <div class="bp-bucket-modal__loading-background"></div>
        <cs-spinner />
      </div>
    </cs-modal>
  </div>
</template>

<script>
import UpdateBucketCredentials from '@/gql/buckets/UpdateBucketCredentials.gql';
import ListBucketsForKeys from '@/gql/buckets/ListBucketsForKeys.gql';

export default {
  components: {},
  props: {
    bucket: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      connecting: false,
      accessKey: '',
      secretKey: '',
      error: '',
    };
  },
  computed: {
    validForm() {
      return !!this.accessKey && !!this.secretKey;
    },
  },
  watch: {
    bucket: {
      immediate: true,
      handler(newVal) {
        this.accessKey = newVal.access_key;
      },
    },
  },
  methods: {
    async onCheckCredentials() {
      this.error = '';
      try {
        this.connecting = true;
        const { data } = await this.$apollo.query({
          query: ListBucketsForKeys,
          variables: {
            providerId: this.bucket.id,
            accessKey: this.accessKey,
            secretKey: this.secretKey,
          },
        });
        if (data) {
          this.onUpdate();
        }
      } catch (err) {
        this.error = err.message;
        this.connecting = false;
      }
    },
    async onUpdate() {
      try {
        await this.$apollo.mutate({
          mutation: UpdateBucketCredentials,
          variables: {
            id: this.bucket.id,
            accessKey: this.accessKey,
            secretKey: this.secretKey,
          },
        });
        this.resetForm();
        this.$emit('success');
      } catch (err) {
        this.error = err.message;
      }
      this.connecting = false;
    },
    onClose() {
      if (this.connecting) return;
      this.resetForm();
      this.$emit('close');
    },
    resetForm() {
      this.accessKey = this.bucket.access_key;
      this.secretKey = '';
    },
  },
};
</script>

<style scoped>
.bp-bucket-modal.cs-modal {
  ---cs-modal-max-height: 600px;
  ---cs-modal-max-height: 90%;
}
.bp-bucket-modal.cs-modal >>> .cs-modal__popup {
  border: none;
}
.bp-bucket-modal__vertical-form {
  padding-top: 16px;
  display: flex;
  flex-direction: column;
}
.bp-bucket-modal__vertical-form >>> .cs-textstyle-label-text {
  font-weight: bold;
}
.bp-bucket-modal__vertical-form >>> .cs-select__label {
  display: block;
}

.bp-bucket-modal__loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bp-bucket-modal__loading,
.bp-bucket-modal__loading-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.bp-bucket-modal__loading-background {
  background-color: var(--cs-gray-06);
  opacity: 0.2;
}
.cs-alert {
  margin-top: 10px;
}
</style>
