<template>
  <div class="bp-filecard-contents">
    <div class="bp-filecard-main">
      <router-link
        :to="{
          name: 'FileDetail',
          params: {
            provider_id: bucket.provider.id,
            bucket_name: bucket.name,
            path_key: path_key,
          },
        }"
      >
        <div class="cs-textstyle-paragraph">
          <span>{{ bucket.name }}</span>
          <span>/</span>
          <span>{{ path_key }}</span>
        </div>
      </router-link>
    </div>
    &nbsp;<i v-if="showAvailable" class="cs-icons-download" @click="openFile()"></i>
  </div>
</template>
<script>
import GetFileBasic from '@/gql/files/GetFileBasic.gql';
import { getFileSignedUrl } from '@/services/fileicon';

export default {
  data() {
    return {
      showAvailable: this.available,
    };
  },
  apollo: {
    file: {
      query: GetFileBasic,
      variables() {
        return {
          provider_id: this.bucket.provider.id,
          bucket_name: this.bucket.name,
          key: this.path_key,
        };
      },
      error(error) {
        // File might be deleted
        this.showAvailable = false;
      },
      skip() {
        return !this.showAvailable;
      },
    },
  },
  props: {
    bucket: {
      type: Object,
      required: true,
    },
    path_key: {
      type: String,
      required: true,
    },
    available: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    async openFile() {
      const url = await getFileSignedUrl(
        this.bucket.provider.id,
        this.bucket.name,
        this.path_key
      );
      window.open(url);
    },
  },
};
</script>
<style scoped>
.bp-filecard-contents {
  display: flex;
  gap: 2px;
}

.bp-filecard-contents i {
  color: var(--cs-primary-base);
}
</style>
