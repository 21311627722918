<template>
  <div>
    <cs-spinner v-if="$apollo.queries.actions.loading" />
    <div v-if="actions">
      <app-action-list :actions="actions" />
    </div>
  </div>
</template>
<script>
import ListImportActions from '@/gql/import/ListImportActions.gql';
import AppActionList from '@/components/actions/ActionList.vue';

export default {
  components: {
    AppActionList,
  },
  apollo: {
    actions: {
      query: ListImportActions,
    },
  },
  data() {
    return {
      search: '',
    };
  },
};
</script>
