<template>
  <app-gray-card>
    <app-list-control-bar
      @refresh="refresh()"
      placeholder="Filter triggers"
      v-model="searchQuery"
    >
      <app-add-trigger :bucketId="bucketId" @added="refresh()" />
    </app-list-control-bar>
    <b-table
      :items="triggers"
      :fields="fields"
      :filter="searchQuery"
      hover
      selectable
      @row-selected="onTriggerSelected"
      outlined
      show-empty
      :busy="$apollo.queries.triggers.loading"
      table-variant="light"
      thead-tr-class="eca-dashboard__table-head-row"
      tbody-tr-class="eca-dashboard__table-row"
    >
      <div slot="table-busy" class="bp-table-empty">
        <cs-spinner />
      </div>
      <div slot="empty" class="bp-table-empty">
        You haven't added any triggers yet.
      </div>
      <div slot="emptyfiltered" class="bp-table-empty">
        No matching triggers.
      </div>
      <template v-slot:cell(created_at)="data">
        <app-display-date-time :value="data.item.created_at" />
      </template>
      <template v-slot:cell(conditions)="data">
        <div v-for="jp of data.item.conditions" :key="jp.key">
          {{ jp.key }}: {{ jp.value }}
        </div>
      </template>
      <template v-slot:cell(job_parameters)="data">
        <div v-for="jp of data.item.job_parameters" :key="jp.key">
          {{ jp.key }}: {{ jp.value }}
        </div>
      </template>
    </b-table>
  </app-gray-card>
</template>
<script>
import ListTriggers from '@/gql/triggers/ListTriggers.gql';
import AppAddTrigger from '@/components/triggers/AddTrigger.vue';
import AppListControlBar from '@/components/general/ListControlBar.vue';
import AppGrayCard from '@/components/general/GrayCard.vue';
import AppDisplayDateTime from '@/components/general/DisplayDateTime.vue';

export default {
  components: {
    AppAddTrigger,
    AppListControlBar,
    AppGrayCard,
    AppDisplayDateTime,
  },
  props: {
    bucketId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      searchQuery: '',
      saving: false,
      fields: [
        {
          key: 'action',
          formatter: (action) => {
            return action.name;
          },
        },
        {
          key: 'created_at',
          label: 'Added',
        },
        {
          key: 'enabled',
        },
        {
          key: 'conditions',
        },
        {
          key: 'job_parameters',
          label: 'Default Parameters',
        },
      ],
    };
  },
  apollo: {
    triggers: {
      query: ListTriggers,
      variables() {
        return {
          bucket_id: this.bucketId,
        };
      },
    },
  },
  methods: {
    refresh() {
      this.$apollo.queries.triggers.refetch();
    },
    onTriggerSelected(rows) {
      const trigger = rows[0];
      if (trigger) {
        this.$router.push({
          name: 'TriggerDetail',
          params: {
            id: trigger.id,
          },
        });
      }
    },
  },
};
</script>
